import React, { useEffect, useState } from 'react'
import useAuthContext from '../../Auth/AuthContext'
import axiosInstance from '../../api/axios'
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { Loading } from './../LoadingPage/Loading';
import { Skeleton } from '@mui/material';
import { StripeCheckOut } from './StripeCheckOut';

const Checkout = () => {
    const { user } = useAuthContext()
    const [step, setStep] = useState(0)
    const intl = useIntl()
    const [subscriptions, setSubscriptions] = useState([])
    const [subscription_id, setSubscription_id] = useState(null)
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkout_Details, setCheckOut_Details] = useState(null);
    // const [Type, setType] = useState('monthly');

    const [type, setType] = useState('monthly');

    const handleToggle = () => {
        setType(type === 'annually' ? 'monthly' : 'annually');
    };

    const getData_subscriptions = async () => {
        setLoading(true)

        const { data } = await axiosInstance.get('/user-subscriptions')
        setSubscriptions(data)
        if (data.length > 0) {
            setSubscription_id(user.bussines.supscription_id);
        }
        setLoading(false)
    }

    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        if (user.bussines.supscription_id !== subscription_id) {

            event.preventDefault()
            const formData = new FormData()
            formData.append('subscription_id', subscription_id);
            formData.append('id', user.bussines.id);
            try {
                await axiosInstance.post(`/owner-business-subscriptions/${user.bussines.id}`, formData).then(response => {
                    setErrors([])
                    // setStep(1)
                    process_payment()

                })
            } catch (e) {
                if (e.response.status === 422) {
                    setErrors(e.response.data.errors)
                }
            }
        }
        else {
            process_payment()
            // setStep(1)
        }
    }

    const process_payment = () => {
        subscriptions.forEach(subscription => {
            if (subscription.id === subscription_id) {
                console.log(subscription);
                window.open(subscription.payment_link + '?prefilled_email=' + user.bussines.owner.email, '_blank', 'noopener,noreferrer');
            }
        });
        // setStep(1)
    }

    const get_subscriptoin = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get('/owner-checkout')
        setCheckOut_Details(data)
        console.log(data);
        setLoading(false)
    }

    useEffect(() => {
        if (user) {
            getData_subscriptions()
        }
    }, [user])


    useEffect(() => {
        if (step === 1) {
            get_subscriptoin()
        }
    }, [step])

    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.Subscriptions' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Subscriptions' })}</li>
                    </ul>
                </div>
            </div>
            <div
                className='d-flex align-items-center justify-content-center'
                onClick={handleToggle}
                style={{ cursor: 'pointer' }}
            >
                <span className={`fs-2 f-bold ${type === 'monthly' ? 'text-primary' : ''}`} >
                    Monthly
                </span>
                <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center mx-3'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        checked={type === 'annually'}
                        readOnly
                    />
                </div>
                <span className={`fs-2 f-bold ${type === 'annually' ? 'text-primary' : ''}`}>
                    Annually
                </span>
            </div>
            <div className="px-5">
                <div className="row flex-start">
                    {subscriptions?.map((row, key) => (
                        (type === 'annually' && row.type === 'year') ||
                            (type !== 'annually' && row.type === 'month') ?
                            (
                                <div className="col-lg-4 col-sm-6" key={key}>
                                    <div className="d-flex card">
                                        <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                            <div className="mb-7 text-center" style={{ height: '150px', position: 'relative' }}>
                                                <h1 className="text-dark mb-5 fw-bolder">
                                                    {intl.locale === 'en' ? row.name_en : row.name_ar}
                                                </h1>
                                                <div className="text-gray-400 fw-semibold mb-5">
                                                    {intl.locale === 'en' ? row.des_en : row.des_ar}
                                                </div>
                                                <div className="text-center d-flex flex-center align-items-baseline w-100" style={{ position: 'absolute', bottom: '0' }}>
                                                    <span className="mb-2 text-primary">$</span>
                                                    <span className="fs-3x fw-bold text-primary">{row.price}</span>
                                                    <span className="fs-7 fw-semibold opacity-50">/
                                                        {type === 'annually' ? 'Year' : 'Month'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-100 mb-10">
                                                {row.details.map((item, index) => (
                                                    <div className="d-flex align-items-center mb-5" key={index}>
                                                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="currentColor" />
                                                                <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 ps-3">
                                                            {intl.locale === 'en' ? item.des_en : item.des_ar}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="d-flex flex-between">
                                                <div onClick={() => setSubscription_id(row.id)} className={subscription_id === row.id ? 'btn btn-primary' : 'btn btn-light-primary'}>
                                                    {subscription_id === row.id ? 'Selected' : 'Select'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                    ))}
                </div>
                <div className="d-flex flex-stack flex-end  pt-15">
                    <div className="mr-2">
                        <button type="button" disabled={!subscription_id} className="btn btn-lg btn-primary" onClick={handleEdit_Add_submit} >
                            {!loading && <span className='indicator-label ms-3 me-3 '>Next </span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            {/* {step === 0 ? !loading ?
                <div className="px-5">
                    <div className="row flex-start">
                        {subscriptions?.map((row, key) => (
                            <div className="col-lg-4 col-sm-6">
                                <div className="d-flex card">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                        <div className="mb-7 text-center" style={{ height: '150px', position: 'relative' }}>
                                            <h1 className="text-dark mb-5 fw-bolder"> {intl.locale === 'en' ? row.name_en : row.name_ar} </h1>
                                            <div className="text-gray-400 fw-semibold mb-5">
                                                {intl.locale === 'en' ? row.des_en : row.des_ar}
                                            </div>
                                            <div className="text-center d-flex flex-center align-items-baseline w-100" style={{ position: 'absolute', bottom: '0' }}>
                                                <span className="mb-2 text-primary">$</span>
                                                {type === 'annually' ?
                                                    <span className="fs-3x fw-bold text-primary">{row.price}</span>
                                                    :
                                                    <span className="fs-3x fw-bold text-primary">{(Number(row.price) / 12).toFixed(2)}</span>
                                                }
                                                <span className="fs-7 fw-semibold opacity-50">/
                                                    {type === 'annually' ? <span data-kt-element="period">Year</span> : <span data-kt-element="period">Month</span>}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w-100 mb-10">
                                            {row.details.map((item, index) => (
                                                <div className="d-flex align-items-center mb-5" key={index}>
                                                    <span className="svg-icon svg-icon-1 svg-icon-success">
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="currentColor" />
                                                            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                                                        </svg>
                                                    </span>
                                                    <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 ps-3">{intl.locale === 'en' ? item.des_en : item.des_ar}   </span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex flex-between">
                                            <div onClick={(e) => setSubscription_id(row.id)} className={subscription_id === row.id ? 'btn btn-primary' : 'btn btn-light-primary'}>  {subscription_id === row.id ? 'Selected' : 'Select'} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex flex-stack flex-end  pt-15">
                        <div className="mr-2">
                            <button type="button" disabled={!subscription_id} className="btn btn-lg btn-primary" onClick={handleEdit_Add_submit} >
                                {!loading && <span className='indicator-label ms-3 me-3 '>Next </span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                :
                <div className="row flex-between">
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                    </div>
                </div>
                :
                step === 1 && !loading ?
                    <div className="row flex-between">
                        <div className="col-8">
                            <StripeCheckOut price={checkout_Details.price} />

                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="d-flex card">
                                <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                    <div className="mb-7 text-center" style={{ height: '150px', position: 'relative' }}>
                                        <h1 className="text-dark mb-5 fw-bolder"> {intl.locale === 'en' ? checkout_Details.name_en : checkout_Details.name_ar} </h1>
                                        <div className="text-gray-400 fw-semibold mb-5">
                                            {intl.locale === 'en' ? checkout_Details.des_en : checkout_Details.des_ar}
                                        </div>
                                        <div className="text-center d-flex flex-center align-items-baseline w-100" style={{ position: 'absolute', bottom: '0' }}>
                                            <span className="mb-2 text-primary">$</span>
                                            <span className="fs-3x fw-bold text-primary">{checkout_Details.price}</span>
                                            <span className="fs-7 fw-semibold opacity-50">/
                                                <span data-kt-element="period">Year</span></span>
                                        </div>
                                    </div>
                                    <div className="w-100 mb-10">
                                        {checkout_Details.details.map((item, index) => (
                                            <div className="d-flex align-items-center mb-5" key={index}>
                                                <span className="svg-icon svg-icon-1 svg-icon-success">
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="currentColor" />
                                                        <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 ps-3">{intl.locale === 'en' ? item.des_en : item.des_ar}   </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row flex-between">
                        <div className="col-8">
                            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                        </div>
                    </div>
            } */}
        </motion.nav>

    )
}

export default Checkout