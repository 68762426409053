import { Navigate, Outlet } from "react-router-dom";
import logo from '../../_metronic/assets/Img/Logo/logo.gif';

import useAuthContext from "../Auth/AuthContext";
import { Loading } from "../pages/LoadingPage/Loading";
import moment from "moment";

const AuthLayout = () => {
    const { user } = useAuthContext();
    const day_date = moment().format('YYYY-MM-DD'); // Current date and time
    // console.log([user.bussines.expiry_date,day_date]);
    if (user === 0) {
        return <Navigate to='/login' />;
    }

    if (!user) {
        return <Loading />;
    }

    if (!user.email_verified_at) {
        return <Navigate to='/Email-Verification' />;
    }

    if (user.type !== 'admin') {
        if (!user.bussines || !user.bussines.supscription_id || !user.bussines.discount || !user.bussines.qr_code) {
            return <Navigate to='/Bussnies-Stepup' />;
        }
        const expiry_date = moment(user.bussines?.expiry_date).format('YYYY-MM-DD');
        // Check if expiry date is in the past (i.e., expired)
        if (!user.bussines.expiry_date || moment(expiry_date).isBefore(day_date)) {
            return <Navigate to='/check-out' />;
        }

        if (user.bussines.status !== 1) {
            return <Navigate to='/pending-request' />;
        }
    }

    return <Outlet />;
};

export default AuthLayout;
