import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../api/axios'
import { Loading } from '../LoadingPage/Loading'
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import google_icon from '../../../_metronic/assets/Wrkbee/User/google.png'
import Axios from 'axios';
import Swal from 'sweetalert2';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup, Paper, Skeleton } from '@mui/material';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const BusinessReview_page = () => {
    const { id } = useParams()
    const [data, setData] = useState([])
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(1);
    const [questions1, setQuestions1] = useState(0);
    const [questions2, setQuestions2] = useState(0);
    const [loading2, setLoading2] = useState(false);

  
    const get_data = async () => {
        setLoading(true);
        const { data } = await axiosInstance.get(`/customer-get-business/${id}`)
        setData(data)
        setLoading(false);
    }

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => handle_google_login(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    const handle_google_login = async (codeResponse) => {
        setLoading2(true);
        try {
            const res = await Axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                headers: {
                    Authorization: `Bearer ${codeResponse.access_token}`,
                    Accept: 'application/json'
                }
            });
            const rating = questions1 + questions2;
            const formData = new FormData();
            formData.append('name', res.data.name);
            formData.append('email', res.data.email);
            formData.append('google_id', res.data.id);
            formData.append('img', res.data.picture);
            formData.append('business_id', data.id);
            formData.append('rating', rating);
            formData.append('questions1', questions1);
            formData.append('questions2', questions2);

            const response = await axiosInstance.post(`/customer-google-sign-up`, formData);
            if(Number(rating) <= 4.5)
            {
                Swal.fire({
                    title: "Great",
                    icon: "success",
                    text: "Thanks for your feedback , reword is send to your email ",
                }); 
            }
            else{
                open_window(data.review_link)
            }
            setLoading2(false);
            
            setErrors([]);
        } catch (err) {
            setLoading2(false);
            Swal.fire({
                title: "sorry",
                icon: "error",
                text: err.response.data.message,
            });
        }
    };

    const open_window = (path) => {
        console.log('Opening window to:', path);
        const newWindow = window.open(path, '_blank');
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // Pop-up was blocked, use fallback
            window.location.href = path;
        }
    };


    useEffect(() => {
        get_data()
    }, [])

    return loading ? <Loading />
        :
        <div className='w-100 login_paner' style={{  height: '100%', minHeight: '100vh'  }}>
            {loading2 ? 
            <div className="row flex-center" style={{ paddingTop:'10vh'}}>
                <Skeleton variant="rounded" animation="wave" sx={{ width: '80%', height: '40vh' }} />
            </div>
            :
            <div>
                {step === 1 &&
                    <Step1 step={step} setStep={setStep} questions1={questions1} setQuestions1={setQuestions1} />
                }

                {step === 2 &&
                    <Step2 step={step} setStep={setStep} questions2={questions2} setQuestions2={setQuestions2} />
                }

                {step === 3 &&
                    <Step3 data={data} login={login} loading={loading} />
                }
            </div>
            }

                {/* <div className='row  flex-center login_paner' style={{ height: '100%', minHeight: '100vh' }}>
                <div className="fw-row col-md-10 col-sm-10 row flex-center ">
                    <div className="card col-10 py-4">
                        <div className="text-end">
                            {data.facebook && data.facebook !== 'null' &&
                                <FaFacebookSquare onClick={() => window.open(data.facebook, '_blank')} style={{ color: 'blue', marginRight: '10px', fontSize: '40px' }} />
                            }
                            {data.youtube && data.youtube !== 'null' &&
                                <FaYoutube onClick={() => window.open(data.youtube, '_blank')} style={{ color: 'red', marginRight: '10px', fontSize: '40px' }} />
                            }
                            {data.twitter && data.twitter !== 'null' &&
                                <FaTwitterSquare onClick={() => window.open(data.twitter, '_blank')} style={{ color: '#00a6ff', marginRight: '10px', fontSize: '40px' }} />
                            }
                            {data.instagram && data.instagram !== 'null' &&
                                <FaInstagramSquare onClick={() => window.open(data.instagram, '_blank')} style={{ color: '#ff008b', marginRight: '10px', fontSize: '40px' }} />
                            }
                            {data.tiktok && data.tiktok !== 'null' &&
                                <FaTiktok onClick={() => window.open(data.tiktok, '_blank')} style={{ color: 'black', marginRight: '10px', fontSize: '40px' }} />
                            }
                        </div>
                        <div className="row flex-center my-10">
                            <div className="card-body text-center pt-0">
                                <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                                    <div className="file">
                                        {data.qr_code &&
                                            <img src={`${process.env.REACT_APP_IMAGE_PATH}${data.img}`} style={{ borderRadius: '15px' }} width={'100%'} height={'100%'} alt="123" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <h1 className='text-center'>{data.name}</h1>
                            <h2 className='text-center'> one step to get your voucher  </h2>
                            <div className="col-11 px-2">
                                <Paper elevation={3} sx={{ px: 3, py: 1 }}>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={handleCheckboxChange}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                            />}
                                            label="Agree To Leave Honest And geninue Review" />
                                    </FormGroup>
                                </Paper>
                            </div>
                            <div className='fv-row col-11 mt-4'>
                                <button disabled={!checked} type='button' onClick={() => login()} className="login2 w-100  d-flex flex-center">
                                    <img src={google_icon} width='20' className='me-3' alt="" />
                                    {!loading && <span className='indicator-label'>Sign In with Google</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                            <h1 className='text-center mt-10'>{data.website}</h1>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
}
const BusinessReview = () => {
    return <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <BusinessReview_page />
    </GoogleOAuthProvider>
}

export default BusinessReview