import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import useAuthContext from '../../../Auth/AuthContext'
import { MdCloudUpload } from 'react-icons/md'
import { FormControl, MenuItem, Select } from '@mui/material'
import axiosInstance from '../../../api/axios'

export const Tab1 = ({ getData, setLoading, loading, business }) => {
  const intl = useIntl()
  const [errors, setErrors] = useState([]);
  const [fileName, setFileName] = useState("No selected file")
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const changeHandler_iamge = (event) => {
    const name = event.target.name;
    setData((prevData) => ({ ...prevData, [name]: event.target.files[0] }));
    setFileName(event.target.files[0].name)
  };

  const [data, setData] = useState({
    name: null,
    phone: null,
    img: null,
    type: 'caffe',
    email: null,
    website: null,
    review_link: null,
  })

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data');
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('_method', 'PATCH');
    try {
      await axiosInstance.post(`/owner-business/${data.id}`, formData).then(response => {
        setLoading(false)
        setErrors([])
        getData()
        // setSetp(step + 1)
      })
    } catch (e) {
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }
  useEffect(() => {
    if (business) {
      setData(business)
    }
  }, [business]);
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bold d-flex align-items-center text-dark">  Business Details </h2>
        <div className="text-muted fw-semibold fs-6">If you need more info, please check out
        </div>
      </div>
      <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
        <div className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" >
          <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.BusinessLogo' })} </h2>
                </div>
              </div>

              <div className="card-body text-center pt-0">
                <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                  <div className="file" >
                    <form onClick={() => document.querySelector(".input-field2").click()} >
                      <input type="file" accept='image/*' className='input-field2' name='img' hidden
                        onChange={changeHandler_iamge}
                      />
                      {data.img === null && data.img_name ? (
                        <img src={`${process.env.REACT_APP_IMAGE_PATH}${data.img_name}`} width={150} height={150} alt="123" />
                      ) : (
                        data.img ? (
                          <img src={URL.createObjectURL(data.img)} width={150} height={150} alt="123" />
                        ) : (
                          <>
                            <MdCloudUpload color="#1475cf" size={60} />
                            <p>{intl.formatMessage({ id: 'Form.BrowseFilestoupload' })}</p>
                          </>
                        )
                      )}
                    </form>
                    {errors.img &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.img}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className=" col-12 mb-3">
                  <div className="mb-3 fv-row fv-plugins-icon-container">
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.BusinessName' })}</label>
                    <input type="text" name="name" className="form-control py-4" value={data.name} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.BusinessName' })} />
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.name &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.name}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                {/* email */}
                <div className=" col-12 mb-3">
                  <div className="mb-3 fv-row fv-plugins-icon-container">
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.BusinessEmail' })} </label>
                    <input type="email" name="email" className="form-control mb-2" value={data.email} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.BusinessEmail' })} />
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.email &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.email}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                <div className=" col-12 mb-3">
                  <div className="mb-3 fv-row fv-plugins-icon-container">
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.BusinessPhone' })}</label>
                    <input type="text" name="phone" className="form-control py-4" value={data.phone} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.BusinessPhone' })} />
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.phone &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.phone}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className=" col-12 mb-3">
                  <div className="mb-3 fv-row fv-plugins-icon-container">
                    <label className="form-label"> {intl.formatMessage({ id: 'Form.BusinessWebsite' })}</label>
                    <input type="text" name="website" className="form-control py-4" value={data.website} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.BusinessWebsite' })} />
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.website &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.website}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className=" col-12 mb-3">
                  <div className="mb-3 fv-row fv-plugins-icon-container">
                    <label className="form-label"> {intl.formatMessage({ id: 'Form.review_link' })}</label>
                    <input type="text" name="review_link" className="form-control py-4" value={data.review_link} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.review_link' })} />
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.review_link &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.review_link}</span>
                          <span role='alert'>{errors.review_link}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className=" col-12 mb-3">
                  <div className="mb-3 fv-row fv-plugins-icon-container">
                    <label className="form-label"> {intl.formatMessage({ id: 'Form.Address' })}</label>
                    <input type="text" name="address" className="form-control py-4" value={data.address} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Address' })} />
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.address &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.address}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="d-flex flex-stack flex-end  pt-15">
        <div className="mr-2">
          <button type="button" disabled={!data.name || !data.email} className="btn btn-lg btn-primary" onClick={handleEdit_Add_submit} >
            {!loading && <span className='indicator-label ms-3 me-3 '>Next </span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
