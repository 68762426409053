import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import axiosInstance from '../../../api/axios';
import { OwnerChartMonth } from '../../../../_metronic/partials/widgets/DashboardCharts/OwnerChartMonth';

const OwnerDashboard = () => {
  const intl = useIntl()

  const [tab_btn, setTab_btn] = useState('month');
  const [data, setData] = useState(null);

  const handle_tabs = async (type) => {
    setTab_btn(type)
  }

  const get_data = async () => {
    const { data } = await axiosInstance.get(`/owner-dashboard`, {
      params: { 'type': tab_btn },
    })
    setData(data)
    console.log(data)
  }

  useEffect(() => {
    get_data()
  }, [tab_btn])
  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.Dashboard' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Dashboard' })}</li>
          </ul>

        </div>
        <div className='card col-lg-6 col-md-12 my-3 col-sm-12' style={{ borderRadius: '8px' }}>
          <div className="row ">
            <div className="col-lg-4 col-md-6 col-sm-6 m-0 p-0">
              <button style={{ borderRadius: '8px 0px 0px 8px' }} onClick={(e) => handle_tabs('last_month')} className={tab_btn === 'last_month' ? 'w-100 dashboard_btn-active ' : 'w-100 dashboard_btn'}> Last Month </button>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 m-0 p-0">
              <button onClick={(e) => handle_tabs('month')} className={tab_btn === 'month' ? 'w-100 dashboard_btn-active' : 'w-100 dashboard_btn'}>This Month</button>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 m-0 p-0">
              <button style={{ borderRadius: '0px 8px 8px 0px' }} onClick={(e) => handle_tabs('year')} className={tab_btn === 'year' ? 'w-100 dashboard_btn-active' : 'w-100 dashboard_btn'}> This Year</button>
            </div>
          </div>
        </div>
      </div>
      <div className="row flex-between my-5">
        <div className="col-4">
          <div className="card">
            <div className="card-header pt-5">
              <div className="card-title d-flex flex-column">
                <div className="card-title d-flex flex-column">
                  <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{data?.reviews ?? 0}</span>
                  <span className="text-gray-500 pt-1 fw-semibold fs-6"> Total Google Reviews</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-header pt-5">
              <div className="card-title d-flex flex-column">
                <div className="card-title d-flex flex-column">
                  <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{data?.vouchers ?? 0}</span>
                  <span className="text-gray-500 pt-1 fw-semibold fs-6">Vouchers</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-header pt-5">
              <div className="card-title d-flex flex-column">
                <div className="card-title d-flex flex-column">
                  <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{data?.Total_Rating_average ?? 0}/10</span>
                  <span className="text-gray-500 pt-1 fw-semibold fs-6">Average QRevu Rating </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data ?
        <div className="card">
          {tab_btn === 'last_month' && <OwnerChartMonth names={data.names} numbers={data.numbers} />}
          {tab_btn === 'month' && <OwnerChartMonth names={data.names} numbers={data.numbers} />}
          {tab_btn === 'year' && <OwnerChartMonth names={data.names} numbers={data.numbers} />}
        </div>
        :
        'loading'
      }
    </motion.nav>
  )
}

export default OwnerDashboard