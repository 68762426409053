import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import useAuthContext from '../../../Auth/AuthContext'
import { MdCloudUpload } from 'react-icons/md'
import { FormControl, MenuItem, Select } from '@mui/material'
import axiosInstance from '../../../api/axios'

export const Tab2 = ({ getData, setLoading, loading , business }) => {
  const [data, setData] = useState(null)
  const intl = useIntl()
  const [subscriptions, setSubscriptions] = useState([])
  const [subscription_id, setSubscription_id] = useState(null)
  const [errors, setErrors] = useState([]);

  const getData_subscriptions = async () => {
    const { data } = await axiosInstance.get('/user-subscriptions')
    setSubscriptions(data)
    if (data.length > 0) {
      setSubscription_id(data[0].id);
    }

  }

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    // event.preventDefault()
    const formData = new FormData()
    formData.append('subscription_id', subscription_id);
    formData.append('id', business.id);
    try {
      await axiosInstance.post(`/owner-business-subscriptions/${business.id}`, formData).then(response => {
        setLoading(false)
        setErrors([])
        getData()
      })
    } catch (e) {
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  useEffect(() => {
    getData_subscriptions()
  }, [])

  useEffect(() => {
    if (subscription_id) {
      handleEdit_Add_submit()
    }
  },[subscription_id])
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bold d-flex align-items-center text-dark">Business  Setup
          <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Billing is issued based on your selected account type" data-bs-original-title="Billing is issued based on your selected account type" data-kt-initialized={1} /></h2>
        <div className="text-muted fw-semibold fs-6">If you need more info, please check out
          <a href="#" className="link-primary fw-bold">Help Page</a>.
        </div>
      </div>
      <div id="kt_app_content_container " >
        <div className="row flex-start">
          {subscriptions?.map((row, key) => (
            <div className="col-lg-4 col-sm-6">
              <div className="d-flex card">
                <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                  <div className="mb-7 text-center" style={{ height: '220px', position: 'relative' }}>
                    <h1 className="text-dark mb-5 fw-bolder"> {intl.locale === 'en' ? row.name_en : row.name_ar} </h1>
                    <div className="text-gray-400 fw-semibold mb-5">
                      {intl.locale === 'en' ? row.des_en : row.des_ar}
                    </div>
                    <div className="text-center d-flex flex-center align-items-baseline w-100" style={{ position: 'absolute', bottom: '0' }}>
                      <span className="mb-2 text-primary">$</span>
                      <span className="fs-3x fw-bold text-primary">{row.price}</span>
                      <span className="fs-7 fw-semibold opacity-50">/
                        <span data-kt-element="period">Year</span></span>
                    </div>
                  </div>
                  <div className="w-100 mb-10">
                    {row.details.map((item, index) => (
                      <div className="d-flex align-items-center mb-5" key={index}>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                          <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="currentColor" />
                            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                          </svg>
                        </span>
                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 ps-3">{intl.locale === 'en' ? item.des_en : item.des_ar}   </span>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex flex-between">
                    <div onClick={(e) => setSubscription_id(row.id)} className={subscription_id === row.id ? 'btn btn-primary' : 'btn btn-light-primary'}>  {subscription_id === row.id ? 'Selected' : 'Select'} </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex flex-stack flex-end  pt-15">
        <div className="mr-2">
          <button type="button" disabled={!subscription_id } className="btn btn-lg btn-primary" onClick={handleEdit_Add_submit} >
            {!loading && <span className='indicator-label ms-3 me-3 '>Next </span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
