import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useAuthContext from '../../Auth/AuthContext';
import { Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import axiosInstance from '../../api/axios';
import ResetPassword from './ResetPassword';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([]);
  const intl = useIntl()
	const navigate = useNavigate()
  const [data, setData] = useState({
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

 
  const handleRegistar = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    try {
      await axiosInstance.post(`/send-otp`, formData).then(response => {
		navigate('/check-otp/'+data.email)
        setLoading(false)
        setErrors([])
      })
    } catch (e) {
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }
   


  return (
    <form onSubmit={handleRegistar} className="row flex-center">
      <img src={process.env.REACT_APP_IMAGE_PATH + (localStorage.getItem('web_img') ?? '')} className='w-200px w-md-50 w-xl-200px me-3' alt="" />

      {/* name */}
      <div className=" col-12 mb-3 px-5">
        <div className="mb-3 fv-row fv-plugins-icon-container">
          <label className="required form-label"> {intl.formatMessage({ id: 'Form.Email' })}</label>
          <input type="text" name="email" className="form-control py-5" value={data.email} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Email' })} />
          <div className="fv-plugins-message-container invalid-feedback" />
          {errors.email &&
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors.email}</span>
              </div>
            </div>
          }
        </div>
      </div>

      <div className='row col-12 mb-3'>
        <div className="col-12">
          <button type='submit' className="btn btn-primary w-100"  >
            {!loading && <span className='indicator-label'>Reset Password</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

     

      </div>

    </form>
  )
}
export default ForgotPassword