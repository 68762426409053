import React from 'react'
import { Navigate, Outlet } from "react-router-dom"
import useAuthContext from "../Auth/AuthContext"
import logo from '../../_metronic/assets/Img/Logo/logo.gif'
import { Loading } from '../pages/LoadingPage/Loading'

const Verification = () => {
  const { user } = useAuthContext()
  if (user === 0) {
    return <Navigate to='/login' />;
  }

  if (!user) {
    return <Loading />;
  }

  if (user.email_verified_at) {
    return <Navigate to='/' />;
  }
  return <Outlet />;
}
export default Verification