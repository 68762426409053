import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../api/axios'
import { Loading } from '../LoadingPage/Loading'
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import google_icon from '../../../_metronic/assets/Wrkbee/User/google.png'
import Axios from 'axios';
import Swal from 'sweetalert2';
import { Skeleton } from '@mui/material';
import { Images } from '../../../_metronic/partials/widgets/React_Table/Images';

const ActiveVoucher = () => {
    const { id } = useParams()
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const get_data = async () => {
        setLoading(true);
        try {
            const { data } = await axiosInstance.get(`/customer-get-voucher/${id}`)
            setData(data)
            setLoading(false);
        }
        catch (err) {
            setErrors([err.message]);
            Swal.fire({
                title: "sorry",
                icon: "error",
                text: "Something Went wrong please try again later!",
            });
        }
    }

    const avtive_voucher = async () => {
        setLoading2(true);
        try {
            await axiosInstance.get(`/customer-active-voucher/${id}`)
            setLoading2(false);
            Swal.fire({
                title: "Great",
                icon: "success",
                text: "Your Reward Has Been Activated",
            });
        }
        catch (err) {
            setErrors([err.message]);
            Swal.fire({
                title: "sorry",
                icon: "error",
                text: "Reward Has Been Activated",
            });
        }
    }


    useEffect(() => {
        get_data()
    }, [])
    return (
        <div className='w-100 login_paner' style={{ height: '100%', minHeight: '100vh' }}>
            <div className="row flex-center mx-3" style={{ paddingTop: '10vh' }}>

                {data !== null && !loading ?
                    <div className="fw-row col-md-10 col-sm-10 row flex-center ">
                        <div className="card col-10 py-4">
                            <div className="text-end">
                                {data.facebook && data.facebook !== 'null' &&
                                    <FaFacebookSquare onClick={() => window.open(data.facebook, '_blank')} style={{ color: 'blue', marginRight: '10px', fontSize: '40px' }} />
                                }
                                {data.youtube && data.youtube !== 'null' &&
                                    <FaYoutube onClick={() => window.open(data.youtube, '_blank')} style={{ color: 'red', marginRight: '10px', fontSize: '40px' }} />
                                }
                                {data.twitter && data.twitter !== 'null' &&
                                    <FaTwitterSquare onClick={() => window.open(data.twitter, '_blank')} style={{ color: '#00a6ff', marginRight: '10px', fontSize: '40px' }} />
                                }
                                {data.instagram && data.instagram !== 'null' &&
                                    <FaInstagramSquare onClick={() => window.open(data.instagram, '_blank')} style={{ color: '#ff008b', marginRight: '10px', fontSize: '40px' }} />
                                }
                                {data.tiktok && data.tiktok !== 'null' &&
                                    <FaTiktok onClick={() => window.open(data.tiktok, '_blank')} style={{ color: 'black', marginRight: '10px', fontSize: '40px' }} />
                                }
                            </div>
                            <div className="row flex-center my-10">
                                <div className="card-body text-center pt-0">
                                    <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                                        <div className="file">
                                            {data.business.qr_code &&
                                                <img src={`${process.env.REACT_APP_IMAGE_PATH}${data.business.img}`} style={{ borderRadius: '15px' }} width={'100%'} height={'100%'} alt="123" />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-center'>{data.business.name}</h1>
                                <div className='d-flex flex-center align-items-center ps-3 my-3'>
                                    {data.cutomer.img ?
                                        <img width={50} style={{ borderRadius: '10%', marginRight: '10px' }} src={data.cutomer.img} alt="" />
                                        :
                                        <Images img={data.cutomer.img} name={data.cutomer.name} />
                                    }
                                    <div className=''>
                                        <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                                            {data.cutomer.name}
                                        </div>
                                        <span className='text-muted d-block fw-semibold text-start'>{data.cutomer.email}</span>
                                    </div>
                                </div>
                                <div className='fv-row col-11 mt-2'>
                                    <button type="button" className="btn btn-lg btn-primary w-100" onClick={avtive_voucher} >
                                        {!loading2 && <span className='indicator-label ms-3 me-3'>Activate Reward</span>}
                                        {loading2 && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                {data.business.discount.discount_type === 'percentage'  &&  
                                    <h4 className='text-center mt-5'>Reward : {data.business.discount.percentage }% OFF  </h4>
                                }

                                {data.business.discount.discount_type === 'voucher'  &&  
                                    <h4 className='text-center mt-5'>Reward : {data.business.discount.discount } OFF  </h4>
                                }
                                
                                {data.business.discount.discount_type === 'item'  &&  
                                    <h4 className='text-center mt-5'>Reward : {data.business.discount.item }  </h4>
                                }

                                <h4 className='text-center ' > Expiry date : {data.expire_date}</h4>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col-12">
                            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '50vh', marginTop: '1vh' }} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ActiveVoucher