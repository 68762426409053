import React, { useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { MdCloudUpload } from 'react-icons/md';
import { useIntl } from 'react-intl';
import axiosInstance from '../../api/axios';
import useAuthContext from '../../Auth/AuthContext';
import { Tab1 } from './Tabs/Tab1';
import { Tab2 } from './Tabs/Tab2';
import { Tab3 } from './Tabs/Tab3';
import { Tab4 } from './Tabs/Tab4';
import { FindBusiness } from './Tabs/FindBusiness';

const BusinessStepUp = () => {
    const intl = useIntl();
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const { getUser } = useAuthContext();

    const [data, setData] = useState({
        uid: null,
        id: null,
        name: null,
        phone: null,
        website: null,
        address: null,
        img: null,
        email: null,
        type: 'caffe',
        qr_code: null,
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
        tiktok: null,
    });

    const getData = async () => {
        try {
            const response = await axiosInstance.get('/owner-business');
            const businessData = response.data;
            setData({
                uid: businessData.uid,
                id: businessData.id,
                name: businessData.name,
                phone: businessData.phone,
                website: businessData.website,
                address: businessData.address,
                img: null,
                img_name: businessData.img,
                email: businessData.email,
                type: businessData.type,
                qr_code: businessData.qr_code,
                facebook: businessData.facebook,
                youtube: businessData.youtube,
                twitter: businessData.twitter,
                instagram: businessData.instagram,
                tiktok: businessData.tiktok,
                review_link: null,
            });

            if (!businessData) {
                setStep(0);
            }
            else if(businessData.step  === 5)
            {
                getUser();
            } 
            else {
                setStep(businessData.step || 0);
            }
        } catch (error) {
            console.error('Error fetching business data:', error);
        }

    };

    useEffect(() => {
        getData();
    }, []); // Empty dependency array to run only once

    return (
        <div className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep" id="kt_create_account_stepper" data-kt-stepper="true">
            <div className="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
                <div className="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center" style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})` }}>
                    <div className="d-flex flex-center pt-10">
                        <img alt="Logo" src={toAbsoluteUrl('/media/Qrevue/background1.png')} className="w-50" />
                    </div>

                    <div className="d-flex flex-row-fluid justify-content-center p-10">
                        <div className="stepper-nav">
                            {['Find Your Business', 'Business Type', 'Business Subscription', 'QrCode', 'Customer Rewards'].map((title, index) => (
                                <div key={index} className={`stepper-item ${step === index ? 'current' : ''}`}>
                                    <div className="stepper-wrapper">
                                        <div className="stepper-icon rounded-3">
                                            <i className="stepper-check fas fa-check" />
                                            <span className="stepper-number">{index + 1}</span>
                                        </div>
                                        <div className="stepper-label">
                                            <h3 className="stepper-title fs-2">{title}</h3>
                                            {index === 1 && <div className="stepper-desc fw-normal">Select your Business type</div>}
                                            {index === 2 && <div className="stepper-desc fw-normal">Choose your Business Subscription</div>}
                                            {index === 3 && <div className="stepper-desc fw-normal">Generate your Business QrCode</div>}
                                            {index === 4 && <div className="stepper-desc fw-normal">Create Business Customer Rewards</div>}
                                        </div>
                                    </div>
                                    {index < 4 && <div className="stepper-line h-40px" />}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-column flex-lg-row-fluid py-10">
                <div className="d-flex flex-center flex-column flex-column-fluid">
                    <div className="w-100 p-10 p-lg-15 mx-auto">
                        <div>
                            <div className={step === 0 ? 'current' : ''} data-kt-stepper-element="content">
                                <FindBusiness getData={getData} business={data} setLoading={setLoading} loading={loading} />
                            </div>

                            <div className={step === 1 ? 'current' : ''} data-kt-stepper-element="content">
                                <Tab1 getData={getData} business={data} setLoading={setLoading} loading={loading} />
                            </div>

                            <div className={step === 2 ? 'current' : ''} data-kt-stepper-element="content">
                                <Tab2 getData={getData } business={data} setLoading={setLoading} loading={loading} />
                            </div>

                            <div className={step === 3 ? 'current' : ''} data-kt-stepper-element="content">
                                <Tab3 getData={getData} business={data} setLoading={setLoading} loading={loading} />
                            </div>

                            <div className={step === 4 ? 'current' : ''} data-kt-stepper-element="content">
                                <Tab4 getData={getData} business={data} setLoading={setLoading} loading={loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessStepUp;
