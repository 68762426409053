import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

const Step2 = ({ step, setStep, questions2, setQuestions2 }) => {

    return (
        <div className="row flex-center mx-3" style={{ paddingTop:'30vh'}}>
            <div className=' col-lg-5 col-sm-10 py-4 text-center'>
                <div className="card">
                    <Box sx={{ '& > legend': { my: 1 } }}>
                        <Typography component="legend" variant="h5">Please rate your service</Typography>
                        <Rating
                            size="large"
                            name="simple-controlled"
                            value={questions2}
                            onChange={(event, newValue) => {
                                setQuestions2(newValue);
                            }}
                        />
                    </Box>
                    <div className="row my-3 flex-center">
                        <Button onClick={() => setStep(step + 1)} sx={{ width: '30%', textAlign: 'center' }} variant="contained">Next</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Step2