import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import useAuthContext from '../../../../../app/Auth/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'

export const SuperAdminSidebar = () => {
    const intl = useIntl()

    return (
        <div>
            <div className='menu-item'>
                <div className='menu-content pt-2 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'> {intl.formatMessage({ id: 'Menu.Application' })}  </span>
                </div>
            </div>

            <SidebarMenuItem
                title={intl.formatMessage({ id: 'Menu.Business' })}
                to='/Business'
                icon='/media/icons/duotune/maps/map008.svg'
                fontIcon='bi-layers'
            />
            <SidebarMenuItem
                title={intl.formatMessage({ id: 'Menu.Subscriptions' })}
                to='/Subscriptions'
                icon='/media/icons/duotune/finance/fin010.svg'
                fontIcon='bi-layers'
            />
            
            <SidebarMenuItem
                title={intl.formatMessage({ id: 'Menu.Users' })}
                to='/AdminUsers'
                icon='/media/icons/duotune/communication/com006.svg'
                fontIcon='bi-layers'
            />
      
            <SidebarMenuItem
                title={intl.formatMessage({ id: 'Menu.WebSiteDetails' })}
                to='/WebDetails/WebSiteDetails'
                icon='/media/icons/duotune/electronics/elc001.svg'
                fontIcon='bi-layers'
            />
        </div>
    )
}
