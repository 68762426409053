import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Box, Rating, Skeleton } from '@mui/material'
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images'
import moment from 'moment'

const OwnerVoucher = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])


    const getData = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get('/owner-get-vouchers')
        setItems(data)
        setLoading(false)
        console.log(data);
    }



    const columns = [

        {
            Header: intl.formatMessage({ id: 'Table.Customer' }),
            accessor: row => `${row.cutomer?.name} ${row.cutomer?.email}`,
            Cell: row => (
                row.row.original.cutomer &&
                <div className='d-flex align-items-center ps-3'>
                    {row.row.original.cutomer.img ?
                        <img width={50} style={{ borderRadius: '10%', marginRight: '10px' }} src={row.row.original.cutomer.img} alt="" />
                        :
                        <Images img={row.row.original.cutomer.img} name={row.row.original.cutomer.name} />
                    }
                    <div className='flex-grow-1'>
                        <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                            {row.row.original.cutomer.name}
                        </div>
                        <span className='text-muted d-block fw-semibold text-start'>{row.row.original.cutomer.email}</span>
                    </div>
                </div>
            ),
            // Define a custom filter function
            filter: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const cutomer = row.values[id]; // id will be 'cutomer' which is the accessor
                    return (
                        cutomer.toLowerCase().includes(filterValue.toLowerCase()) // Search on concatenated string
                    );
                });
            }
        },
        {
            Header: intl.formatMessage({ id: 'Table.Status' }),
            accessor: 'deleted_at',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {row.original.deleted_at ?
                        <span className="badge badge-light-success"> Activated </span>
                        :
                        <span className="badge badge-light-warning"> Not Used </span>
                    }
                </div>


            )
        },
        {
            Header: 'order rating',
            accessor: 'questions1',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    <Box sx={{ '& > legend': { my: 1 } }}>
                        <Rating
                            readOnly
                            size="large"
                            name="simple-controlled"
                            value={row.original.questions1}
                        />
                    </Box>
                </div>
            )
        },
        {
            Header: 'service rating',
            accessor: 'questions2',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    <Box sx={{ '& > legend': { my: 1 } }}>
                        <Rating
                            readOnly
                            size="large"
                            name="simple-controlled"
                            value={row.original.questions2}
                        />
                    </Box>
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Expire' }),
            accessor: 'expire_date',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {row.original.expire_date}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Date' }),
            accessor: 'created_at',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {moment(row.original.created_at).format('DD-MM-YYYY')}
                </div>
            )
        },
    ];

    useEffect(() => {
        getData()
    }, [])



    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
        >
            <div className="mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        {intl.formatMessage({ id: 'Menu.Vouchers' })}
                    </h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            {intl.formatMessage({ id: 'Menu.Home' })}
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">
                            {intl.formatMessage({ id: 'Menu.Vouchers' })}
                        </li>
                    </ul>
                </div>
            </div>

            {items.length > 0 && !loading ?
                <ReactTable columns={columns} data={items} customComponent={<></>} />
                :
                <div className="row">
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                    </div>
                </div>
            }
        </motion.nav>
    )
}

export default OwnerVoucher
