import React, { useEffect, useRef, useState } from 'react'
import { MdCloudUpload } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { QRCode } from 'react-qrcode-logo';
import axiosInstance from '../../../../../api/axios';
import Swal from 'sweetalert2';

export const BusinessQrCode = ({ get_data, business }) => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const qrCodeRef = useRef(null);
  const [qrCode, setQrCode] = useState({
    value: process.env.REACT_APP_APP_URL + '/business/' + business.id,
    bgColor: '#ffffff',
    fgColor: '#000000',
    img: null,
    logoWidth: 50,
    logoHeight: 50,
  });

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const file = business.img;
        const response = await axiosInstance.post(`/download`, { file }, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const reader = new FileReader();
        reader.onloadend = () => {
          setQrCode((prevData) => ({ ...prevData, img: reader.result }));
        };
        reader.readAsDataURL(new Blob([response.data]));
      } catch (error) {
        console.error('Error downloading the file', error);
      }
    };

    if (business) {
      fetchLogo();
      setQrCode((prevData) => ({
        ...prevData,
        value: process.env.REACT_APP_APP_URL + '/business/' + business.uid,
        bgColor: '#ffffff',
        fgColor: '#000000',
        logoWidth: 50,
        logoHeight: 50,
      }));
    }
  }, [business]);
  console.log(qrCode.value);
  const handleEdit_Add_submit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append('enctype', 'multipart/form-data');
    const canvas = qrCodeRef.current.querySelector('canvas');
    formData.append('id', business.id);

    if (canvas) {
      canvas.toBlob((blob) => {
        formData.append('qr_code', new File([blob], 'qr-code.png', { type: 'image/png' }));
        axiosInstance.post(`/admin-business-qrcode/${business.id}`, formData)
          .then(response => {
            Swal.fire({
              title: "Success",
              icon: "success",
              confirmButtonText: "OK",
            });
            setLoading(false);
            setErrors([]);
            get_data();
          })
          .catch(e => {
            console.log(e.response.data.errors);
            if (e.response.status === 422) {
              setLoading(false);
              setErrors(e.response.data.errors);
            }
          });
      }, 'image/png');
    } else {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Failed to generate QR code image.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const changeHandler_image = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setQrCode((prevData) => ({ ...prevData, img: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = () => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.querySelector('canvas');
      if (canvas) {
        const link = document.createElement('a');
        link.href = canvas.toDataURL();
        link.download = 'qr-code.png';
        link.click();
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQrCode((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div className='row flex-between w-100'>
      <div className="col-6">
        <div className="card p-5">
          <div className="row">
            <div className="mb-3 col-12 fv-row fv-plugins-icon-container">
              <label className="required form-label">{intl.formatMessage({ id: 'Form.Color' })}</label>
              <input
                type="color"
                style={{ height: '60px' }}
                name="fgColor"
                className="form-control mb-2"
                value={qrCode.fgColor}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'Form.Color' })}
              />
              <div className="fv-plugins-message-container invalid-feedback" />
              {errors.fgColor && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.fgColor}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="mb-3 col-12 fv-row fv-plugins-icon-container">
              <label className="required form-label">{intl.formatMessage({ id: 'Form.BackgroundColor' })}</label>
              <input
                type="color"
                style={{ height: '60px' }}
                name="bgColor"
                className="form-control mb-2"
                value={qrCode.bgColor}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'Form.BackgroundColor' })}
              />
              <div className="fv-plugins-message-container invalid-feedback" />
              {errors.bgColor && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.bgColor}</span>
                  </div>
                </div>
              )}
            </div>
            {qrCode.img && (
              <>
                <div className="mb-3 col-12 fv-row fv-plugins-icon-container">
                  <label className="required form-label">{intl.formatMessage({ id: 'Form.LogoWidth' })}</label>
                  <input
                    type="range"
                    min="10"
                    max="60"
                    name="logoWidth"
                    className="form-range mb-2"
                    value={qrCode.logoWidth}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3 col-12 fv-row fv-plugins-icon-container">
                  <label className="required form-label">{intl.formatMessage({ id: 'Form.LogoHeight' })}</label>
                  <input
                    type="range"
                    min="10"
                    max="60"
                    name="logoHeight"
                    className="form-range mb-2"
                    value={qrCode.logoHeight}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="col-4">
        <div className="card" ref={qrCodeRef}>
          <QRCode
            value={qrCode.value}
            bgColor={qrCode.bgColor}
            fgColor={qrCode.fgColor}
            logoImage={qrCode.img}
            ecLevel="H"
            size={300}
            quietZone={20}
            logoWidth={qrCode.logoWidth}
            logoHeight={qrCode.logoHeight}
            removeQrCodeBehindLogo={false}
            logoPadding={5}
            logoPaddingStyle="circle"
            qrStyle="squares"
            eyeRadius={20}
          />

          <div className="row flex-between mt-4 ">
            <div className="col-6">
              <button type="button" className="btn btn-primary w-100" onClick={handleDownload}>
                {intl.formatMessage({ id: 'Form.Download' })}
              </button>
            </div>

            <div className="col-6">
              <button type="button" onClick={handleEdit_Add_submit} className="btn btn-primary w-100">
                {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Save' })} </span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
