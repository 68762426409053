import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import axiosInstance from '../../../api/axios';
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';
import LocationsAutocomplete from '../../../../_metronic/partials/widgets/AutoComplete/LocationsAutocomplete';
import axios from 'axios';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable';
import { FaExclamationCircle } from 'react-icons/fa';

export const FindBusiness = ({ getData, setLoading, loading }) => {
    const intl = useIntl();
    const DATAFORSEO_API_KEY = 'admin@qrevu.co.uk';
    const DATAFORSEO_API_SECRET = '28f861627ed8875b';

    const [Location, setLocation] = useState(null);
    const [Searchloading, setSearchLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const [items, setItems] = useState(null);
    const [data, setData] = useState({
        location_name: null,
        language_name: 'ENGlish (United Kingdom)',
        keyword: null,
        place_id: null,
        name: null,
        phone: null,
        category: null,
        website: null,
        address: null
    });

    const handleSearch = async () => {
        setSearchLoading(true)
        const url = "https://api.dataforseo.com/v3/serp/google/maps/live/advanced";
        const auth = btoa(`${DATAFORSEO_API_KEY}:${DATAFORSEO_API_SECRET}`);
        const request = [
            {
                "language_name": data.language_name,
                "location_name": data.location_name,
                "keyword": data.keyword
            }
        ];
        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${auth}`
                }
            });
            console.log('Response:', response.data);
            console.log('Response:', response.data.status_code);
            console.log('Response:', response.data.tasks[0].result[0].items);
            setSearchLoading(false)
            if (response.data.status_code === 20000) {
                const result = response.data.tasks.length > 0 ? response.data.tasks[0].result : null;
                setItems(response.data.tasks[0].result[0].items);
                setErrors([]);
            } else {
                setErrors(response.data.status_message || 'Unknown error');
                setItems([]);
            }
        } catch (err) {
            setSearchLoading(false)
            setErrors(err.message);
            setItems([]);
        }
    };

    const handle_select = (item) => {
        console.log(item);
        setData({
            ...data,
            place_id: item.place_id,
            name: item.title,
            phone: item.phone,
            category: item.category,
            website: item.domain,
            address: item.address,
        })
    };


    const handleEditAddSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        try {
            await axiosInstance.post(`/owner-business`, formData).then(response => {
                setLoading(false);
                setErrors([]);
                getData();
                // setSetp(step + 1)
            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                setLoading(false);
                setErrors(e.response.data.errors);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.File' }), accessor: 'main_image',
            Cell: row => (
                <div className="symbol symbol-50px ">
                    <img src={row.row.original.main_image ? row.row.original.main_image : toAbsoluteUrl("media/avatars/300-6.jpg")} alt="" />
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Title' }), accessor: 'title',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.title}
                </div>
            )
        },

        {
            Header: intl.formatMessage({ id: 'Table.Phone' }), accessor: 'phone',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.phone}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Address' }), accessor: 'address',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.address}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.WebSite' }), accessor: 'domain',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.domain}
                </div>
            )
        },

        {
            Header: intl.formatMessage({ id: 'Table.Action' }),
            Cell: row => (
                <div className="d-flex flex-center">
                    <button onClick={(e) => [handle_select(row.row.original)]} className={data.place_id == row.row.original.place_id ? 'btn me-2  btn-secondary' : 'btn me-2  btn-light-primary'}>
                        {data.place_id == row.row.original.place_id ?
                            intl.formatMessage({ id: 'Table.Selected' })
                            :
                            intl.formatMessage({ id: 'Table.Select' })
                        }
                    </button>
                </div>
            ),
        },
    ];


    useEffect(() => {
        if (Location) {
            setData({
                ...data,
                location_name: Location.location_name,
            })
        }
        setErrors([]);
    }, [Location]);
    const find_business_title = 'Please use the search box to enter your business name and locate it in the list below. Once you search for your business, you’ll see different results. You can refine your search by entering additional business attributes such as location. Select the correct business from the list to proceed with your registration.'
    return (
        <div className="w-100">
            <div className="card w-100 p-5">

                <div className="pb-10 pb-lg-15">
                    <h2 className="fw-bold d-flex align-items-center text-dark">
                        Find Your Business
                        <Tooltip title={find_business_title} arrow >
                            <IconButton>
                                <FaExclamationCircle style={{ fontSize: ' 16px', margin: '0px 0px 4px 0px' }} />
                            </IconButton>
                        </Tooltip>
                    </h2>
                    <div className="text-muted fw-semibold fs-6">
                        Please search and select your business
                    </div>
                </div>
                <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                    <div className="row flex-between align-items-center">
                        <div className="col-4">
                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.Location' })}  </label>
                            <LocationsAutocomplete value={Location} setValue={setLocation} />
                            <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        <div className="col-6">
                            <div className="mb-3 fv-row fv-plugins-icon-container">
                                <label className="required form-label"> Business Name  </label>
                                <input type="text" name="keyword" className="form-control py-5" value={data.keyword} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.keyword' })} />
                                <div className="fv-plugins-message-container invalid-feedback" />
                                {errors.keyword &&
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{errors.keyword}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-2">
                            <button type="button" onClick={handleSearch} disabled={!data.language_name || !data.keyword} className="btn btn-primary mt-4 w-100 py-5">
                                {!Searchloading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Search' })} </span>}
                                {Searchloading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card w-100 p-5 my-3">
                {items !== null &&
                    <ReactTable columns={columns} data={items} customComponent={<></>} />
                }
            </div>
            <div className="d-flex flex-stack flex-end pt-15">
                <div className="mr-2">
                    <button type="button" className="btn btn-lg btn-primary" onClick={handleEditAddSubmit}>
                        {!loading && <span className='indicator-label ms-3 me-3'>Next</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};
