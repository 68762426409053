/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import useAuthContext from '../../Auth/AuthContext'
import AdminDashboard from '../Admin/Dashboard/AdminDashboard'
import OwnerDashboard from '../Owner/Dashboard/OwnerDashboard'


const DashboardWrapper = () => {
  const {user} = useAuthContext()
  console.log(user);
  return  user.type === 'admin' ? <AdminDashboard /> : <OwnerDashboard />
}

export default DashboardWrapper