import React from 'react'
import useAuthContext from '../Auth/AuthContext'
import { Navigate, Outlet } from 'react-router-dom'
import { Loading } from '../pages/LoadingPage/Loading'

const PendingLayout = () => {
    const { user } = useAuthContext()
    return user !== 0 ?
        !user ?
            <Loading />
            :
            user.type !== 'admin' ?
                !user.bussines ?
                    <Navigate to='/Bussnies-Stepup' />
                    :
                    user.bussines.status !== 1 ?
                        <Outlet />
                        :
                        <Navigate to='/' />
                :
                <Navigate to='/' />
        : <Navigate to='/login' />
}

export default PendingLayout