import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import useAuthContext from '../../../../../app/Auth/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'

export const OwnerSidebar = () => {
  const intl = useIntl()

  return (
    <div>
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'> {intl.formatMessage({ id: 'Menu.Application' })}  </span>
        </div>
      </div>
      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Dashboard' })}
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.BusinessDetails' })}
        to='/BusinessDetails'
        icon='/media/icons/duotune/general/gen001.svg'
        fontIcon='bi-layers'
      />
      
  
      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Reviews' })}
        to='/Reviews'
        icon='/media/icons/duotune/communication/com003.svg'
        fontIcon='bi-layers'
      />
      
      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Users' })}
        to='/Owner/Users'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-layers'
      />
      
      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Vouchers' })}
        to='/Vouchers'
        icon ='/media/icons/duotune/ecommerce/ecm003.svg'
        fontIcon='bi-layers'
      />
      
      {/* <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'> {intl.formatMessage({ id: 'Menu.Products' })}  </span>
        </div>
      </div>

      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Categories' })}
        to='/Categories'
        icon='/media/icons/duotune/abstract/abs015.svg'
        fontIcon='bi-layers'
      />

      <SidebarMenuItemWithSub to='/Products' title={intl.formatMessage({ id: 'Menu.Products' })} icon='/media/icons/duotune/ecommerce/ecm007.svg' hasBullet={false}>
        <SidebarMenuItem to='/Products/Products' title={intl.formatMessage({ id: 'Menu.Products' })} hasBullet={true} />
        <SidebarMenuItem to='/Products/Shortcomings' title={intl.formatMessage({ id: 'Menu.Shortcomings' })} hasBullet={true} />
        <SidebarMenuItem to='/Products/NewArrivals' title={intl.formatMessage({ id: 'Menu.NewArrivals' })} hasBullet={true} />
        <SidebarMenuItem to='/Products/TopSelling' title={intl.formatMessage({ id: 'Menu.TopSelling' })} hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Orders' })}
        to='/Orders'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
        fontIcon='bi-layers'
      /> */}


    </div>
  )
}
