import React, { useEffect, useState } from 'react'
import useAuthContext from '../../../Auth/AuthContext'
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { KTSVG } from '../../../../_metronic/helpers';
import { Box, Skeleton, Tab, Tabs } from '@mui/material';
import { BusinessSettings } from './Tabs/BusinessSettings';
import { BusinessQrCode } from './Tabs/BusinessQrCode';
import { BusinessRewords } from './Tabs/BusinessRewords';
import { FaPhoneAlt } from 'react-icons/fa';

const BusinessDetails = () => {
    const { user, getUser } = useAuthContext();
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const [value, setValue] = useState(0);
    const [data, setData] = useState({
        name: null,
        phone: null,
        img: null,
        email: null,
        type: 'caffe',
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };


    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        event.preventDefault()
        const formData = new FormData()
        formData.append('enctype', 'multipart/form-data');
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        try {
            await axiosInstance.post(`/owner-business`, formData).then(response => {
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setLoading(false)
                setErrors([])
                getUser()
            })
        } catch (e) {
            console.log(e.response.data.errors);
            if (e.response.status === 422) {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
        }
    }
    const manage_Subscription = () => {
        const url = data.subscreption.cancel_link+ '?prefilled_email=' + user.bussines.owner.email
        window.open(url, '_blank', 'noopener,noreferrer');
    }
    //owner-business
    const getData = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get('/owner-business')
        setData({
            id: data.id,
            uid: data.uid,
            name: data.name,
            phone: data.phone,
            img: data.img,
            email: data.email,
            type: data.type,
            qr_code: data.qr_code,
            facebook: data.facebook,
            youtube: data.youtube,
            twitter: data.twitter,
            instagram: data.instagram,
            tiktok: data.tiktok,
            discount: data.discount,
            website: data.website,
            review_link: data.review_link,
            subscreption: data.subscreption,

        })
        setLoading(false)
    }


    useEffect(() => {
        getData()
    }, [])
    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.BusinessDetails' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.BusinessDetails' })}</li>
                    </ul>
                </div>
            </div>
            {user ?
                <div>
                    <div className='card mb-3 mb-xl-5'>
                        <div className='card-body pt-5 pb-0'>
                            <div className='d-flex overflow-auto '>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} aria-label="basic tabs example">
                                            <Tab label={intl.formatMessage({ id: 'Menu.MyBusiness' })} onClick={(e) => setValue(0)} />
                                            <Tab label={intl.formatMessage({ id: 'Menu.QrCode' })} onClick={(e) => setValue(1)} />
                                            <Tab label={intl.formatMessage({ id: 'Menu.CustomerRewards' })} onClick={(e) => setValue(2)} />
                                            <Tab label={intl.formatMessage({ id: 'Menu.manage_Subscription' })} onClick={manage_Subscription} />
                                        </Tabs>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                    <div>
                        {value === 0 && <BusinessSettings get_data={getData} business={data} />}
                        {value === 1 && <BusinessQrCode get_data={getData} business={data} />}
                        {value === 2 && <BusinessRewords get_data={getData} business={data} />}
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '40vh', marginTop: '5vh' }} />
                    </div>
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '60vh', marginTop: '1vh' }} />
                    </div>
                </div>
            }

        </motion.nav>

    )
}

export default BusinessDetails