import { Navigate, Outlet } from "react-router-dom";
import logo from '../../_metronic/assets/Img/Logo/logo.gif';

import useAuthContext from "../Auth/AuthContext";
import { Loading } from "../pages/LoadingPage/Loading";

const BussniesStepUpLayout = () => {
    const { user } = useAuthContext();

    if (user === 0) {
        return <Navigate to='/login' />;
    }

    if (!user) {
        return <Loading />;
    }
    
    if (!user.email_verified_at) {
        return <Navigate to='/Email-Verification' />;
    }

    if (user.type === 'admin') {
        return <Navigate to='/' />;
    }

    if (user.bussines && user.bussines.supscription_id && user.bussines.discount && user.bussines.qr_code) {
        return <Navigate to='/' />;
    }

    return <Outlet />;
};

export default BussniesStepUpLayout;
