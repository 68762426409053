import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { KTSVG } from '../../../../_metronic/helpers';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const AddSubscriptions = () => {
    const intl = useIntl()
    const [user_value, setUSER_Value] = useState(null);
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const [types, setTypes] = useState([]);
    const navigate = useNavigate();
    const periodical_types = [
        { id: 'month', name: 'Month' },
        { id: 'year', name: 'Year' },
    ];
    const [rowsData, setRowsData] = useState([{
        des_en: '',
        des_ar: '',
    }]);

    const [data, setData] = useState({
        name_en: null,
        cancel_link: null,
        name_ar: null,
        des_en: null,
        des_ar: null,
        payment_link: null,
        price: 0,
        payment_link: null,
        price_id: null,
        duration: 0,
        type: 'month',
    });

    const addTableRows = () => {
        const rowsInput = {
            des_en: '',
            des_ar: '',
        }
        setRowsData([...rowsData, rowsInput])
    }

    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }

    const handleChange2 = (index, evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
        console.log(rowsData);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };


    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        event.preventDefault()
        const formData = new FormData()
        formData.append('enctype', 'multipart/form-data');
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append('details', JSON.stringify(rowsData));
        if (user_value && data.type !== 'none') {
            formData.append('merchant_id', user_value.id);
        }

        try {
            await axiosInstance.post(`/admin-subscriptions`, formData).then(response => {
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setLoading(false)
                setErrors([])
                navigate(`/Subscriptions`)
            })
        } catch (e) {
            console.log(e.response.data.errors);
            if (e.response.status === 422) {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
        }
    }



    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Subscriptions.Create' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Subscriptions' })}</li>
                    </ul>
                </div>
                <div>
                    <Link to='/Subscriptions' type='button' className='btn btn-primary' >
                        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
                        {intl.formatMessage({ id: 'Form.GoBack' })}
                    </Link>
                </div>
            </div>
            <div id="kt_app_content_container" className="app-container container-xxl">
                <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                <div className="row flex-between">

                                    <div className="mb-3 col-12 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Name' })}  </label>
                                        <input type="text" name="name_en" className="form-control py-4" value={data.name_en} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameEN' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.name_en &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.name_en}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.payment_link' })}  </label>
                                        <input type="text" name="payment_link" className="form-control py-4" value={data.payment_link} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.payment_link' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.payment_link &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.payment_link}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.cancel_link' })}  </label>
                                        <input type="text" name="cancel_link" className="form-control py-4" value={data.cancel_link} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.cancel_link' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.cancel_link &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.cancel_link}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.periodicalType' })}  </label>
                                        <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="type"
                                            onChange={handleChange}
                                            value={data.type}
                                        >
                                            {periodical_types.map((row, key) => (
                                                <MenuItem key={key} value={row.id}>
                                                    {row.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.name_en &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.name_en}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Duration' })}  </label>
                                        <input type="number" name="duration" className="form-control py-4" value={data.duration} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameEN' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.duration &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.duration}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
 

                                    <div className="mb-3  col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Price' })}</label>
                                        <input type="text" name="price" className="form-control py-4" value={data.price} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Price' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.price &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.price}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="mb-3  col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Price_ID' })}</label>
                                        <input type="text" name="price_id" className="form-control py-4" value={data.price_id} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Price_ID' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.price_id &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.price_id}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.ItemDetails' })}</h2>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="mb-3 fv-row fv-plugins-icon-container mt-3 ">
                                        <div>
                                            <label className="form-label">{intl.formatMessage({ id: 'Form.DiscretionEN' })}</label>
                                            <textarea name="des_en" className='form-control py-4'
                                                maxLength="250"
                                                onChange={handleChange}
                                                cols="30" rows="5" value={data.des_en} >
                                            </textarea>
                                        </div>
                                    </div>
                                    {/* <div className="mb-3 fv-row fv-plugins-icon-container mt-3 col-lg-6 col-sm-12">
                                        <div>
                                            <label className="form-label">{intl.formatMessage({ id: 'Form.DiscretionAR' })}</label>
                                            <textarea name="des_ar" className='form-control py-4'
                                                maxLength="250"
                                                onChange={handleChange}
                                                cols="30" rows="5" value={data.des_ar}>
                                            </textarea>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Table.Points' })}</h2>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="mb-3 fv-row fv-plugins-icon-container mt-3 ">
                                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                                            <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                                                <tr>
                                                    <th>{intl.formatMessage({ id: 'Form.DiscretionEN' })}</th>
                                                    {/* <th>{intl.formatMessage({ id: 'Form.DiscretionAR' })}</th> */}
                                                    <th>
                                                        <button type='button' className='btn me-2 btn-light-primary' onClick={addTableRows} >+</button>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rowsData.map((row, key) => (
                                                    <tr key={key}>
                                                        <td style={{ width: '400px' }}>
                                                            <input type="text" value={row.des_en} onChange={(evnt) => (handleChange2(key, evnt))} name="des_en" className="form-control" />
                                                        </td>
                                                        {/* <td style={{ width: '400px' }}>
                                                            <input type="text" value={row.des_ar} onChange={(evnt) => (handleChange2(key, evnt))} name="des_ar" className="form-control" />
                                                        </td> */}
                                                        <td>
                                                            <div className="d-flex flex-center">
                                                                <button type='button' className='btn me-2 btn-light-primary' onClick={addTableRows} >+</button>
                                                                <button className='btn me-2 btn-light-danger' type='button' onClick={() => (deleteTableRows(key))}>x</button>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-20">
                            <button type="submit" className="btn btn-primary">
                                {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Create' })} </span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </motion.nav>
    )
}
