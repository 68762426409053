import React, { useEffect } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useThemeMode } from '../../../_metronic/partials'
import useAuthContext from '../../Auth/AuthContext'
const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']
const PendingRequest = () => {
    const {user , logout} = useAuthContext()
    const {mode} = useThemeMode()
    useEffect(() => {
      BODY_CLASSES.forEach((c) => document.body.classList.add(c))
      document.body.style.backgroundImage =
        mode === 'dark'
          ? `url(${toAbsoluteUrl('/media/auth/bg7-dark.jpg')})`
          : `url(${toAbsoluteUrl('/media/auth/bg7.jpg')})`
  
      return () => {
        BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
        document.body.style.backgroundImage = 'none'
      }
    }, [mode])
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
             
                <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
                <div className='fw-semibold fs-6 text-gray-500 mb-7'>Your Account is recently Pending </div>
                <div className='mb-3'>
                <img
                    src={toAbsoluteUrl('/media/auth/404-error.png')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                />
                <img
                    src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
                    className='mw-100 mh-300px theme-dark-show'
                    alt=''
                />
                </div>
                <div className='mb-0'>
                <a  onClick={logout} className='btn btn-sm btn-primary'>
                    Sign Out
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PendingRequest

