import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton } from '@mui/material'
import { FaCopy } from "react-icons/fa";
import ReactTableWithPagention from '../../../../_metronic/partials/widgets/React_Table/ReactTableWithPagention'
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images'

const ReviewsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [inputValue, setInputValue] = useState(null);

  const get_data = async () => {
    const { data } = await axiosInstance.get(`/owner-reviews?page=${currentPage}`, {
      params: { 'name': inputValue },
    })
    setTotalPages(data.meta.last_page);

    setItems(data.data)
  }

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'id',
      Cell: row => (
        <div className=" w-100 flex-center">
          #  {row.row.original.id}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Customer' }), accessor: 'File',
      Cell: row => (
        <div className="symbol symbol-50px ">
          {row.row.original.customer.img ?
            <img width={50} style={{ borderRadius: '10%', marginRight: '10px' }} alt="user" src={row.row.original.customer.img}  />
            :
            <Images img={row.row.original.customer.img} name={row.row.original.customer.name} />
          }
          {row.row.original.customer.name}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Review' }), accessor: 'review',
      Cell: row => (
        <div className=" w-100 flex-center">
          {row.row.original.review}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Date' }), accessor: 'date',
      Cell: row => (
        <div className=" w-100 flex-center">
          {row.row.original.created_at}
        </div>
      )
    },
  ];

  useEffect(() => {
    setItems([])
    get_data()
  }, [inputValue, currentPage])

  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.Reviews' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Reviews' })}</li>
          </ul>
        </div>
      </div>
      {Items !== null && !loading ?
        <ReactTableWithPagention
          columns={columns}
          data={Items}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          inputValue={inputValue}
          setInputValue={setInputValue}
          customComponent={<></>} />
        :
        <div className="row">
          <div className="col-12">
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
          </div>
        </div>
      }

    </motion.nav>
  )
}
//get-banners
export default ReviewsHome