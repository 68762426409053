import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import axiosInstance from '../../../api/axios';
import { Button, FormControl, IconButton, Input, InputAdornment, MenuItem, Select, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';
import { FaExclamationCircle } from "react-icons/fa";

export const Tab4 = ({ getData, business, setLoading, loading }) => {
  const intl = useIntl()

  const discount_types = [
    {
      id: 'percentage',
      name: intl.formatMessage({ id: 'Form.Percentage' }),
    },
    {
      id: 'voucher',
      name: intl.formatMessage({ id: 'Form.Voucher' }),
    },
    {
      id: 'item',
      name: intl.formatMessage({ id: 'Form.Item' }),
    },
  ]

  const periodical_types = [
    {
      id: 'day',
      name: intl.formatMessage({ id: 'Form.Day' }),
    },
    {
      id: 'month',
      name: intl.formatMessage({ id: 'Form.Month' }),
    },
    {
      id: 'year',
      name: intl.formatMessage({ id: 'Form.Year' }),
    },
  ]

  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    name: null,
    discount_type: 'percentage',
    percentage: 0,
    up_to: 0,
    discount: 0,
    periodical_type: 'month',
    periodical_duration: 1,
    item: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEdit_Add_submit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append('enctype', 'multipart/form-data');
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    try {
      await axiosInstance.post(`/owner-business-discount/${business.id}`, formData).then(response => {
        // Swal.fire({
        //   title: "Success",
        //   icon: "success",
        //   confirmButtonText: "OK",
        // });
        setLoading(false);
        setErrors([]);
        getData();
      });
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false);
        setErrors(e.response.data.errors);
      }
    }
  };

  useEffect(() => {
    if (business.discount) {
      setData({
        name: business.discount.name,
        discount_type: business.discount.discount_type,
        percentage: business.discount.percentage,
        up_to: business.discount.up_to,
        discount: business.discount.discount,
        periodical_type: business.discount.periodical_type,
        periodical_duration: business.discount.periodical_duration,
        item: business.discount.item,
      });
    }
  }, [business]);
  return (
    <div className="card">
      <form onSubmit={handleEdit_Add_submit}>
        <div className='row p-5 flex-between'>

          <div className="card-title">
            <h2>{intl.formatMessage({ id: 'Form.CustomerRewards' })} 
            <Tooltip title="You can set the incentive you want to offer your customers for reviewing your business.
                How does it work?
                When your customer leaves you a review, they will receive the reward you set here. 
                Your customers will be rewarded once only because each customer can review your business only once. Use the settings below to configure your customer incentives.
                First, choose the type of incentive you will offer. Next, select the amount and set the expiry date for your reward.
                In this way, your customers will get what you offer them when they leave a review, but only before the expiry date. 
                Finally, reward your customers in your business. Ask them to show you the reward they received, each reward will come with a QR code that you can scan to validate if the reward is active and valid. " arrow
                >
              <IconButton>
                <FaExclamationCircle style={{ fontSize: ' 16px', margin: '0px 0px 4px 0px' }} />
              </IconButton>
            </Tooltip>
            </h2>
           
          </div>

          {/* Name */}
          {/* <div className="my-5  col-6 fv-row fv-plugins-icon-container">

            <label className="required form-label"> {intl.formatMessage({ id: 'Form.RewordTitle' })}</label>
            <input
              type="text"
              name="name"
              className="form-control py-4"
              value={data.name}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'Form.RewordTitle' })}
            />
            <div className="fv-plugins-message-container invalid-feedback" />
            {errors.name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{errors.name}</span>
                </div>
              </div>
            )}
          </div> */}

          {/* Discount Type */}
          <div className="my-5  col-12 fv-row fv-plugins-icon-container">
            <label className="required form-label"> {intl.formatMessage({ id: 'Form.DiscountType' })}</label>
            <Tooltip title="Please select the type of reward you want to offer your customers. This reward will be given to customers once they leave a review for your business." arrow >
              <IconButton>
                <FaExclamationCircle style={{ fontSize: ' 16px', margin: '0px 0px 4px 0px' }} />
              </IconButton>
            </Tooltip>
            <FormControl fullWidth>
              <Select
                name="discount_type"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleChange}
                value={data.discount_type}
              >
                {discount_types.map((row, key) => (
                  <MenuItem key={key} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.discount_type &&
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.discount_type}</span>
                </div>
              </div>
            }
          </div>

          {/* Percentage */}
          {data.discount_type === 'percentage' &&
            <>
              <div className="my-5  col-6 fv-row fv-plugins-icon-container">
                <label className="required form-label"> {intl.formatMessage({ id: 'Form.Percentage' })}</label>
                <Tooltip title=" Offer your customer percentage on their bill, you can also set the minimum amount to be spent for the discount to be applied" arrow >
                  <IconButton>
                    <FaExclamationCircle style={{ fontSize: ' 16px', margin: '0px 0px 4px 0px' }} />
                  </IconButton>
                </Tooltip>
                <input
                  type="number"
                  name="percentage"
                  className="form-control py-4"
                  value={data.percentage}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({ id: 'Form.Percentage' })}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
                {errors.percentage &&
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.percentage}</span>
                    </div>
                  </div>
                }
              </div>
              <div className="my-5  col-6 fv-row fv-plugins-icon-container">
                <label className="required form-label"> {intl.formatMessage({ id: 'Form.Maxmumspend' })}</label>
                <Tooltip title="Please set the minimum amount customers must spend to be eligible for the voucher or the discount." arrow >
                  <IconButton>
                    <FaExclamationCircle style={{ fontSize: ' 16px', margin: '0px 0px 4px 0px' }} />
                  </IconButton>
                </Tooltip>
                <input
                  type="number"
                  name="up_to"
                  className="form-control py-4"
                  value={data.up_to}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({ id: 'Form.Minimumspend' })}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
                {errors.up_to &&
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.up_to}</span>
                    </div>
                  </div>
                }
              </div>
            </>
          }

          {/* Discount */}
          {data.discount_type === 'voucher' &&
            <>
              <div className="my-5  col-12 fv-row fv-plugins-icon-container">

                <label className="required form-label"> {intl.formatMessage({ id: 'Form.Voucher' })}</label>
                <Tooltip title="Offer your customers a fixed voucher amount, you can also set the minimum amount to be spent for the voucher to be applied" arrow >
                  <IconButton>
                    <FaExclamationCircle style={{ fontSize: ' 16px', margin: '0px 0px 4px 0px' }} />
                  </IconButton>
                </Tooltip>
                <Input
                  startAdornment={<InputAdornment position="start">£</InputAdornment>}
                  type="number"
                  name="discount"
                  className="form-control py-4"
                  value={data.discount}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({ id: 'Form.Voucher' })}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
                {errors.discount &&
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.discount}</span>
                    </div>
                  </div>
                }
              </div>
            </>
          }
          {data.discount_type === 'item' &&
            <>
              <div className="my-5  col-12 fv-row fv-plugins-icon-container">
                <label className="required form-label"> {intl.formatMessage({ id: 'Form.Item' })}</label>
                <Tooltip title="Offer your customers a specific item such as a Soft drink, a coffee etc.." arrow >
                  <IconButton>
                    <FaExclamationCircle style={{ fontSize: ' 16px', margin: '0px 0px 4px 0px' }} />
                  </IconButton>
                </Tooltip>
                <input
                  type="test"
                  name="item"
                  className="form-control py-4"
                  value={data.item}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({ id: 'Form.Item' })}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
                {errors.item &&
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.item}</span>
                    </div>
                  </div>
                }
              </div>
            </>
          }

          <div className="card-title mb-0 mt-5">
            <h2>{intl.formatMessage({ id: 'Form.ExpireDate' })} </h2>
          </div>

          <div className="my-2  col-4 fv-row fv-plugins-icon-container">
            <label className="required form-label"> {intl.formatMessage({ id: 'Form.DiscountType' })}</label>
            <Tooltip title="Please set the duration for your customer incentives. This is how long the customer rewards will be active. For example, if you set the expiration date to 1 week, the customer will have only 1 week to use their reward within. " arrow >
              <IconButton>
                <FaExclamationCircle style={{ fontSize: ' 16px', margin: '0px 0px 4px 0px' }} />
              </IconButton>
            </Tooltip>
            <FormControl fullWidth>
              <Select
                name="periodical_type"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleChange}
                value={data.periodical_type}
              >
                {periodical_types.map((row, key) => (
                  <MenuItem key={key} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.periodical_type &&
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.periodical_type}</span>
                </div>
              </div>
            }
          </div>

          <div className="my-5   col-8 fv-row fv-plugins-icon-container">
            <label className="required form-label"> {intl.formatMessage({ id: 'Form.Duration' })}</label>
            <input
              type="number"
              name="periodical_duration"
              className="form-control py-4"
              value={data.periodical_duration}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'Form.Duration' })}
            />
            <div className="fv-plugins-message-container invalid-feedback" />
            {errors.periodical_duration &&
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.periodical_duration}</span>
                </div>
              </div>
            }
          </div>

          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {!loading && <span className="indicator-label">{intl.formatMessage({ id: 'Form.Add' })} </span>}
              {loading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>

        </div>
      </form>
    </div>
  )
}
