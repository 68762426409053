import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { App } from '../App'
import Login from '../pages/Auth/Login'
import ForgotPassword from '../pages/Auth/ForgotPassword'
import ResetPassword from '../pages/Auth/ResetPassword'
import EmailVerification from '../pages/Auth/EmailVerification'
import GuestLayout from '../Layout/GuestLayout'
import Verification from '../Layout/Verification'
import AuthLayouy from '../Layout/AuthLayouy'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { AnimatePresence } from 'framer-motion'
import { useLang } from '../../_metronic/i18n/Metronici18n'
import Profile from '../pages/Auth/Profile'
import PendingLayout from '../Layout/PendingLayout'
import PendingRequest from '../pages/PendingRequest/PendingRequest'
import WebDetails from '../pages/Admin/WebDetails/WebDetails'
import BusinessHome from '../pages/Admin/Bussines/BusinessHome'
import AdminLayout from '../Layout/AdminLayout'
import BusinessDetails from '../pages/Owner/BusinessDetails/BusinessDetails'
import SubscriptionsHome from '../pages/Admin/Subscriptions/SubscriptionsHome'
import { AddSubscriptions } from '../pages/Admin/Subscriptions/AddSubscriptions'
import { UpdateSubscriptions } from '../pages/Admin/Subscriptions/UpdateSubscriptions'
import AdminUsersHome from '../pages/Admin/AdminUsers/AdminUsersHome'
import { AddAdminUsers } from '../pages/Admin/AdminUsers/AddAdminUsers'
import { UpdateAdminUsers } from '../pages/Admin/AdminUsers/UpdateAdminUsers'
import BussniesStepUpLayouy from '../Layout/BussniesStepUpLayouy'
import BussniesStepUp from '../pages/BussniesStepUp/BussniesStepUp'
import OwnerUsersHome from '../pages/Owner/OwnerUsers/OwnerUsersHome'
import { AddOwnerUsers } from '../pages/Owner/OwnerUsers/AddOwnerUsers'
import { EditOwnerUsers } from '../pages/Owner/OwnerUsers/EditOwnerUsers'
import CustomersHome from '../pages/Owner/Customers/CustomersHome'
import ReviewsHome from '../pages/Owner/Reviews/ReviewsHome'
import AuthContainer from '../pages/Auth/AuthContainer'
import Registar from '../pages/Auth/Registar'
import CheckOtp from '../pages/Auth/CheckOtp'
import BusinessReview from '../pages/Customers/BusinessReview'
import AdminBusinessProfile from '../pages/Admin/Bussines/BusinessProfile/AdminBusinessProfile'
import BuseinessAPi from '../pages/Test/BuseinessAPi'
import ActiveVoucher from '../pages/Customers/ActiveVoucher'
import OwnerVoucher from '../pages/Owner/Vouchers/OwnerVoucher'
import Checkout from '../pages/Checkout/Checkout'
import CheckLayout from '../Layout/CheckLayout'


const AppRoutes = () => {
  const location = useLocation()

  const lang = useLang();
  useEffect(() => {
    if (lang === 'ar') {

      require('../../_metronic/assets/style/style_rtl.css');
      console.log('ar_ar');
    }
    else {
      require('../../_metronic/assets/style/style.css');
      console.log('en_en');
    }
  }, [location])

  function changeFavicon(newIconUrl) {
    var oldIcon = document.querySelector('link[rel="icon"]');
    if (oldIcon) {
      oldIcon.parentNode.removeChild(oldIcon);
    }

    var newIcon = document.createElement('link');
    newIcon.rel = 'icon';
    newIcon.href = newIconUrl;

    document.head.appendChild(newIcon);
  }

  const img = process.env.REACT_APP_IMAGE_PATH + localStorage.getItem('web_img');
  useEffect(() => {
    changeFavicon(img);
    document.title = localStorage.getItem('web_name');
    document.description = localStorage.getItem('web_name');
  }, []);

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route element={<App />}>
          <Route path='*' element={<Navigate to='/dashboard' />} />
          <Route path='error/*' element={<ErrorsPage />} />


          <Route element={<GuestLayout />}>
            <Route element={<AuthContainer />}>
              <Route path='/login' element={<Login />} />
              <Route path='/Register' element={<Registar />} />

              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/check-otp/:email' element={<CheckOtp />} />
              <Route path="/password-reset/:code/:email" element={<ResetPassword />} />
            </Route>
          </Route>

          <Route element={<PendingLayout />}>
            <Route path='/pending-request' element={<PendingRequest />} />
          </Route>

          <Route element={<Verification />}>
            <Route element={<AuthContainer />}>
              <Route path='/Email-Verification' element={<EmailVerification />} />
            </Route>
          </Route>

          <Route element={<BussniesStepUpLayouy />}>
            <Route path='/Bussnies-Stepup' element={<BussniesStepUp />} />
          </Route>

          <Route element={<AuthLayouy />}>
            <Route element={<MasterLayout />}>
              <Route path='dashboard' element={<DashboardWrapper />} />
              <Route path='profile' element={<Profile />} />

              {/* Admin Layout */}
              <Route element={<AdminLayout />}>
                {/* WebDetails */}
                <Route path='WebDetails/WebSiteDetails' element={<WebDetails />} />

                {/* Bussines */}
                <Route path='Business' element={<BusinessHome />} />
                <Route path='Admin-Business-Profile/:id' element={<AdminBusinessProfile />} />

                {/* Subscriptions */}
                <Route path='Subscriptions' element={<SubscriptionsHome />} />
                <Route path='Add-Subscriptions' element={<AddSubscriptions />} />
                <Route path='Edit-Subscriptions/:id' element={<UpdateSubscriptions />} />


                {/* AdminUsers */}
                <Route path='AdminUsers' element={<AdminUsersHome />} />
                <Route path='Add-AdminUsers' element={<AddAdminUsers />} />
                <Route path='Edit-AdminUsers/:id' element={<UpdateAdminUsers />} />

              </Route>

              {/* Owner */}
              <Route path='Owner/Users' element={<OwnerUsersHome />} />
              <Route path='Owner/Add-Users' element={<AddOwnerUsers />} />
              <Route path='Owner/Edit-Users/:id' element={<EditOwnerUsers />} />

              <Route path='BusinessDetails' element={<BusinessDetails />} />

              <Route path='Customers' element={<CustomersHome />} />
              <Route path='Reviews' element={<ReviewsHome />} />
              <Route path='Vouchers' element={<OwnerVoucher />} />



            </Route>

          </Route>

          <Route element={<CheckLayout />} >
            <Route element={<MasterLayout/>} >
              <Route path='check-out' element={<Checkout />} />
            </Route>
          </Route>
    

          {/* Customers */}
          <Route path='business/:id' element={<BusinessReview />} />
          <Route path='check-review/:id' element={<ActiveVoucher />} />

          <Route path='search' element={<BuseinessAPi />} />

        </Route>
      </Routes>
    </AnimatePresence>
    //admin-countries
  )
}

export default AppRoutes