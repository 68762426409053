import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton } from '@mui/material'

const SubscriptionsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [search, setSearch] = useState('')

  const CustomDiv = () => (
    <div className="row flex-end w-100">
      <div className="col-auto">
        <Link to='/Add-Subscriptions' type='button' className='btn btn-primary'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({ id: 'Subscriptions.Create' })}
        </Link>
      </div>
    </div>
  );

  const getData = async () => {
    setLoading(true)
      const { data } = await axiosInstance.get('/admin-subscriptions')
      console.log('Fetched Data:', data)
      setItems(data)
   
      setLoading(false)
  }

  const deleteCategory = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const { data } = await axiosInstance.delete(`/admin-subscriptions/${id}`);
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data));
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Done!",
          icon: "success"
        });
      }
    });
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }),
      accessor: 'id',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          # {row.original.id}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Description' }),
      accessor: 'name_en',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.name_en}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Price' }),
      accessor: 'price',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.price}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Action' }),
      Cell: ({ row }) => (
        <div className="d-flex flex-center">
          <Link to={`/Edit-Subscriptions/${row.original.id}`} className='btn me-2 btn-light-primary'>
            {intl.formatMessage({ id: 'Table.Edit' })}
          </Link>
          <button onClick={() => deleteCategory(row.original.id)} className='btn me-2 btn-light-danger'>
            {intl.formatMessage({ id: 'Table.Delete' })}
          </button>
        </div>
      )
    }
  ];

  useEffect(() => {
    getData()
  }, [])

  const filteredItems = items.filter(item =>
    item.name_en.toLowerCase().includes(search.toLowerCase()) ||
    item.name_ar.toLowerCase().includes(search.toLowerCase()) ||
    item.type.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
    >
      <div className="mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
            {intl.formatMessage({ id: 'Menu.Subscriptions' })}
          </h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Home' })}
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Subscriptions' })}
            </li>
          </ul>
        </div>
      </div>
      
      { !loading ?
        <ReactTable columns={columns} data={filteredItems} customComponent={<CustomDiv />} />
        :
        <div className="row">
          <div className="col-12">
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
          </div>
        </div>
      }
    </motion.nav>
  )
}

export default SubscriptionsHome
