import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useAuthContext from '../../Auth/AuthContext';
import { Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import logo from '../../../_metronic/assets/Img/Logo/logo.gif'
import google_icon from '../../../_metronic/assets/Wrkbee/User/google.png'
import axios from '../../api/axios';
import ReCAPTCHA from "react-google-recaptcha";
import { useIntl } from 'react-intl';
import axiosInstance from '../../api/axios';
import Swal from 'sweetalert2';


const Registar = () => {
  const { getUser, TokenSave } = useAuthContext();
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([]);
  const [security, setSecurity] = useState(null)
  const intl = useIntl()

  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
    confirem_password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onChange = (e) => {
    setSecurity(e)
  }

  const handleRegistar = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    try {
      await axiosInstance.post(`/sign-up`, formData).then(response => {
        TokenSave('access_token', response.data.token)
        getUser()
        setLoading(false)
        setErrors([])
      })
    } catch (e) {
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  return (
    <form onSubmit={handleRegistar} className="row flex-center">
      <img src={process.env.REACT_APP_IMAGE_PATH + (localStorage.getItem('web_img') ?? '')} className='w-200px w-md-50 w-xl-200px me-3' alt="" />

      {/* name */}
      <div className=" col-12 mb-3 px-5">
        <div className="mb-3 fv-row fv-plugins-icon-container">
          <label className="required form-label"> {intl.formatMessage({ id: 'Form.Name' })}</label>
          <input type="text" name="name" className="form-control py-5" value={data.name} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Name' })} />
          <div className="fv-plugins-message-container invalid-feedback" />
          {errors.name &&
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors.name}</span>
              </div>
            </div>
          }
        </div>
      </div>


      {/* email */}
      <div className=" col-12 mb-3 px-5">
        <div className="mb-3 fv-row fv-plugins-icon-container">
          <label className="required form-label"> {intl.formatMessage({ id: 'Form.Email' })} </label>
          <input type="email" name="email" className="form-control py-5" value={data.email} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Email' })} />
          <div className="fv-plugins-message-container invalid-feedback" />
          {errors.email &&
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors.email}</span>
              </div>
            </div>
          }
        </div>
      </div>

      {/* Password */}
      <div className=" col-12 mb-3 px-5">
        <div className="mb-3 fv-row fv-plugins-icon-container">
          <label className="required form-label"> {intl.formatMessage({ id: 'Form.Password' })} </label>
          <input type="password" name="password" className="form-control py-5" value={data.password} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Password' })} />
          <div className="fv-plugins-message-container invalid-feedback" />
          {errors.password &&
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors.password}</span>
              </div>
            </div>
          }
        </div>
      </div>

      <div className=" col-12 mb-3 px-5">
        <div className="mb-3 fv-row fv-plugins-icon-container">
          <label className="required form-label">{intl.formatMessage({ id: 'Form.ConfirmPassword' })} </label>
          <input type="password" name="confirem_password" className="form-control py-5" value={data.confirem_password} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.ConfirmPassword' })} />
          <div className="fv-plugins-message-container invalid-feedback" />
          {errors.confirem_password &&
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors.confirem_password}</span>
              </div>
            </div>
          }
        </div>
      </div>

      <div className="fv-row col-11 mb-3">
        <ReCAPTCHA sitekey="6LdLNhAmAAAAANFJxdmha-Pz3IK4L1XHa9S-R4l_" onChange={onChange} />
      </div>

      <div className='fv-row col-12 mb-3'>
        <button type='submit' className="btn btn-primary w-100" disabled={security == null}>
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

    </form>
  )
}
export default Registar