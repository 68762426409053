import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import useAuthContext from '../../../../../app/Auth/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'
import { SuperAdminSidebar } from './SuperAdminSidebar'
import { OwnerSidebar } from './OwnerSidebar'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {user} = useAuthContext()

  return user && user.type == 'admin' ?
        <SuperAdminSidebar />
      :
      <OwnerSidebar />
}

export { SidebarMenuMain }
