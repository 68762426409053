import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { Skeleton } from '@mui/material';
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl';
import { MdCloudUpload } from 'react-icons/md';
import axiosInstance from '../../../api/axios';
import useAuthContext from '../../../Auth/AuthContext';

const WebDetails = () => {
  const intl = useIntl()
  const { get_data_web } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState([]);


  const get_data = async () => {
    const { data } = await axiosInstance.get('/admin-details', {
      params: {
        type: 'web'
      }
    })
    console.log(data);
    setData({
      name_en: data.name_en,
      phone1: data.phone1,
      phone2: data.phone2,
      whatsapp: data.whatsapp,
      email: data.email,
      address: data.address,
      facebook: data.facebook,
      youtube: data.youtube,
      instagram: data.instagram,
      img_name: data.img,
      img: null,
    
      icon_name: data.icon,
      icon: null,
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };


  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data');
    formData.append('type', 'web');
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);

    });
    try {
      await axiosInstance.post(`/admin-details`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        get_data_web()
        get_data()
        setLoading(false)
        setErrors([])
      })
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  const changeHandler_iamge = (event) => {
    const name = event.target.name;
    setData((prevData) => ({ ...prevData, [name]: event.target.files[0] }));
  };
  useEffect(() => {
    get_data()
  }, [])


  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.WebDetails' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.WebDetails' })}</li>
          </ul>
        </div>
      </div>
      {data !== null ?
        <div id="kt_app_content_container" className="app-container container-xxl">
          <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
            <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>{intl.formatMessage({ id: 'Form.ImageContent' })} </h2>
                  </div>
                </div>
                <div className="card-body text-center pt-0">
                  <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                    <div className="file" >
                      <form onClick={() => document.querySelector(".input-field2").click()} >
                        <input type="file" accept='image/*' className='input-field2' name='img' hidden
                          onChange={changeHandler_iamge}
                        />
                        {data.img === null && data.img_name ?
                          <img src={process.env.REACT_APP_IMAGE_PATH + data.img_name} width="60%" alt='' />
                          :
                          data.img ?
                            <img width="60%" src={URL.createObjectURL(data.img)} alt='' />
                            :
                            <>
                              <MdCloudUpload color='#1475cf' size={60} />
                              <p> {intl.formatMessage({ id: 'Form.BrowseFilestoupload' })} </p>
                            </>
                        }
                      </form>
                      {errors.img &&
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.img}</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>{intl.formatMessage({ id: 'Table.Icon' })} </h2>
                  </div>
                </div>
                <div className="card-body text-center pt-0">
                  <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                    <div className="file" >
                      <form onClick={() => document.querySelector(".input-field5").click()} >
                        <input type="file" accept='image/*' className='input-field5' name='icon' hidden
                          onChange={changeHandler_iamge}
                        />
                        {data.icon === null && data.icon_name ?
                          <img src={process.env.REACT_APP_IMAGE_PATH + data.icon_name} width="60%" alt='' />
                          :
                          data.icon ?
                            <img width="60%" src={URL.createObjectURL(data.icon)} alt='' />
                            :
                            <>
                              <MdCloudUpload color='#1475cf' size={60} />
                              <p> {intl.formatMessage({ id: 'Form.BrowseFilestoupload' })} </p>
                            </>
                        }
                      </form>
                      {errors.icon &&
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.icon}</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div> */}

            </div>
            <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="card-body pt-0">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Name' })}</label>
                        <input type="text" name="name_en" className="form-control mb-2" value={data.name_en} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Name' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.name_en &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.name_en}</span>
                            </div>
                          </div>
                        }
                      </div>

                    </div>
                  </div>
                  {/* <div className="col-6">
                    <div className="card-body pt-0">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.email' })}</label>
                        <input type="email" name="email" className="form-control mb-2" value={data.email} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.email' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.email &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.email}</span>
                            </div>
                          </div>
                        }
                      </div>

                    </div>
                  </div>

                  <div className="col-6">
                    <div className="card-body pt-0">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Phone1' })}</label>
                        <input type="tel" name="phone1" className="form-control mb-2" value={data.phone1} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Phone1' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.phone1 &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.phone1}</span>
                            </div>
                          </div>
                        }
                      </div>

                    </div>
                  </div>

                  <div className="col-6">
                    <div className="card-body pt-0">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Phone2' })}</label>
                        <input type="tel" name="phone2" className="form-control mb-2" value={data.phone2} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Phone2' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.phone2 &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.phone2}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card-body pt-0">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.whatsapp' })}</label>
                        <input type="number" name="whatsapp" className="form-control mb-2" value={data.whatsapp} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.whatsapp' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.whatsapp &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.whatsapp}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card-body pt-0">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.instagram' })}</label>
                        <input type="text" name="instagram" className="form-control mb-2" value={data.instagram} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.instagram' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.instagram &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.instagram}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card-body pt-0">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.facebook' })}</label>
                        <input type="text" name="facebook" className="form-control mb-2" value={data.facebook} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.facebook' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.facebook &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.facebook}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card-body pt-0">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.youtube' })}</label>
                        <input type="text" name="youtube" className="form-control mb-2" value={data.youtube} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.youtube' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.youtube &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.youtube}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div> */}

                </div>





              </div>
              <div className="d-flex justify-content-end ">
                <button type="submit" className="btn btn-primary">
                  {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Create' })} </span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        :
        <div className="row">
          <div className="col-12">
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
          </div>
        </div>
      }
    </motion.nav>
  )
}

export default WebDetails