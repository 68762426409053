import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../api/axios'
import { Loading } from '../LoadingPage/Loading'
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import google_icon from '../../../_metronic/assets/Wrkbee/User/google.png'
import Axios from 'axios';
import Swal from 'sweetalert2';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup, Paper } from '@mui/material'

const Step3 = ({ data, login, loading }) => {
  const [checked, setChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="row flex-center mx-3" style={{ paddingTop:'10vh'}}>
      <div className=' col-lg-5 col-sm-10 py-4 text-center'>
        <div className="card">
          <div >
            {data.facebook && data.facebook !== 'null' &&
              <FaFacebookSquare onClick={() => window.open(data.facebook, '_blank')} style={{ color: 'blue', marginRight: '10px', fontSize: '40px' }} />
            }
            {data.youtube && data.youtube !== 'null' &&
              <FaYoutube onClick={() => window.open(data.youtube, '_blank')} style={{ color: 'red', marginRight: '10px', fontSize: '40px' }} />
            }
            {data.twitter && data.twitter !== 'null' &&
              <FaTwitterSquare onClick={() => window.open(data.twitter, '_blank')} style={{ color: '#00a6ff', marginRight: '10px', fontSize: '40px' }} />
            }
            {data.instagram && data.instagram !== 'null' &&
              <FaInstagramSquare onClick={() => window.open(data.instagram, '_blank')} style={{ color: '#ff008b', marginRight: '10px', fontSize: '40px' }} />
            }
            {data.tiktok && data.tiktok !== 'null' &&
              <FaTiktok onClick={() => window.open(data.tiktok, '_blank')} style={{ color: 'black', marginRight: '10px', fontSize: '40px' }} />
            }
          </div>
          <div className="row flex-center my-10">
            <div className="card-body text-center pt-0">
              <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                <div className="file">
                  {data.qr_code &&
                    <img src={`${process.env.REACT_APP_IMAGE_PATH}${data.img}`} style={{ borderRadius: '15px' }} width={'70%'} height={'70%'} alt="123" />
                  }
                </div>
              </div>
            </div>
            <h2 className='text-center'> You are one step away from getting your reward  </h2>
            <div className="col-11 px-2">
              <Paper elevation={1} sx={{ px: 3, py: 1 }}>
                <FormGroup>
                  <FormControlLabel control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />}
                    label="i agree to leave honest and geninue review" />
                </FormGroup>
              </Paper>
            </div>
            <div className='fv-row col-11 mt-4'>
              <button disabled={!checked} type='button' onClick={() => login()} className="login2 w-100  d-flex flex-center">
                <img src={google_icon} width='20' className='me-3' alt="" />
                {!loading && <span className='indicator-label'>Sign In with Google</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            <h1 className='text-center mt-10'>{data.name}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Step3