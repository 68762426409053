import React, { useState } from 'react';
import axios from 'axios';

const DATAFORSEO_API_KEY = 'admin@qrevu.co.uk';
const DATAFORSEO_API_SECRET = '28f861627ed8875b';

const BusinessAPI = () => {
  const [keyword, setKeyword] = useState('kfc');
  const [languageName, setLanguageName] = useState('English (United Kingdom)');
  const [locationName, setLocationName] = useState('egypt');
  const [taskInfo, setTaskInfo] = useState(null);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    const url = "https://api.dataforseo.com/v3/serp/google/maps/live/advanced";
    const auth = btoa(`${DATAFORSEO_API_KEY}:${DATAFORSEO_API_SECRET}`);

    const data = [
      {
        "language_name": languageName,
        "location_name": locationName,
        "keyword": keyword
      }
    ];

    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${auth}`
        }
      });

      console.log('Response:', response); // Log the entire response object

      if (response.data.status === 'ok') {
        console.log('Response Data:', response.data); // Log response data
        console.log('Response Tasks:', response.data.tasks); // Log tasks data

        // Adjust this based on the actual response structure
        const result = response.data.tasks.length > 0 ? response.data.tasks[0].result : null;
        setTaskInfo(result);
        setError(null);
      } else {
        setError(response.data.status_message || 'Unknown error');
        setTaskInfo(null);
      }
    } catch (err) {
      console.error('Error:', err); // Log the error
      setError(err.message);
      setTaskInfo(null);
    }
  };

  return (
    <div>
      <h1>Business API Search</h1>
      <div>
        <input
          type="text"
          placeholder="Keyword"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <input
          type="text"
          placeholder="Language Name"
          value={languageName}
          onChange={(e) => setLanguageName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Location Name"
          value={locationName}
          onChange={(e) => setLocationName(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {taskInfo && (
        <div>
          <h2>Task Information</h2>
          <ul>
            {taskInfo.map((info, index) => (
              <li key={index}>
                <strong>Location ID:</strong> {info.location_id} <br />
                <strong>Result:</strong> {JSON.stringify(info)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BusinessAPI;
