import React, { useEffect, useState } from 'react';
import { MdCloudUpload } from 'react-icons/md';
import { useIntl } from 'react-intl';
import axiosInstance from '../../../../api/axios';
import Swal from 'sweetalert2';

export const BusinessSettings = ({ business, get_data }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [fileName, setFileName] = useState("No selected file");
  const [data, setData] = useState({
    id: business.id,
    name: business.name,
    phone: business.phone,
    img: null,
    img_name: business.img,
    qr_code: null,
    qr_code_name: business.qr_code,
    email: business.email,
    facebook: business.facebook,
    youtube: business.youtube,
    twitter: business.twitter,
    instagram: business.instagram,
    tiktok: business.tiktok,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEdit_Add_submit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append('enctype', 'multipart/form-data');
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('_method', 'PATCH');
    try {
      await axiosInstance.post(`/owner-business/${data.id}`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading(false);
        setErrors([]);
        get_data();
        // navigate(`/`)
      });
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false);
        setErrors(e.response.data.errors);
      }
    }
  };

  const changeHandler_image = (event) => {
    const { name, files } = event.target;
    if (files && files[0]) {
      setData((prevData) => ({ ...prevData, [name]: files[0] }));
      if (name === 'img') {
        setFileName(files[0].name);
      }
    }
  };
useEffect(() => {
  get_data();
}, []);
  useEffect(() => {
    setData({
      id: business.id,
      name: business.name,
      phone: business.phone,
      img: null,
      img_name: business.img,
      qr_code: null,
      qr_code_name: business.qr_code,
      email: business.email,
      facebook: business.facebook !== 'null' ? business.facebook : '',
      youtube: business.youtube !== 'null' ? business.youtube : '',
      twitter: business.twitter !== 'null' ? business.twitter : '',
      instagram: business.instagram !== 'null' ? business.instagram : '',
      tiktok: business.tiktok !== 'null' ? business.tiktok : '',
      website: business.website !== 'null' ? business.website : '',
      review_link: business.review_link !== 'null' ? business.review_link : '',
    });
  }, [business]);


  
  const handleDownload = async (event) => {
    event.preventDefault();
    const file = data.qr_code_name;
    try {
      const response = await axiosInstance.post(`/download`, { file }, { responseType: 'blob', });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  return (
    <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
      <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
        <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
          <div className="card card-flush py-4">
            <div className="card-header">
              <div className="card-title">
                <h2>{intl.formatMessage({ id: 'Form.Logo' })} </h2>
              </div>
            </div>
            <div className="card-body text-center pt-0">
              <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                <div className="file">
                  <form onClick={() => document.querySelector(".input-field2").click()}>
                    <input
                      type="file"
                      accept="image/*"
                      className="input-field2"
                      name="img"
                      hidden
                      onChange={changeHandler_image}
                    />
                    {data.img === null && data.img_name ? (
                      <img src={`${process.env.REACT_APP_IMAGE_PATH}${data.img_name}`} width={150} height={150} alt="123" />
                    ) : (
                      data.img ? (
                        <img src={URL.createObjectURL(data.img)} width={150} height={150} alt="123" />
                      ) : (
                        <>
                          <MdCloudUpload color="#1475cf" size={60} />
                          <p>{intl.formatMessage({ id: 'Form.BrowseFilestoupload' })}</p>
                        </>
                      )
                    )}
                  </form>
                  {errors.img && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors.img}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="card card-flush py-4">
            <div className="card-header">
              <div className="card-title">
                <h2>{intl.formatMessage({ id: 'Form.QrCode' })} </h2>
              </div>
            </div>
            <div className="card-body text-center pt-0">
              <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                <div className="file">
                  {data.qr_code_name &&
                    <img src={`${process.env.REACT_APP_IMAGE_PATH}${data.qr_code_name}`} width={'100%'} height={'100%'} alt="123" />
                  }
                  <button
                    onClick={handleDownload}
                    className="btn btn-primary mt-3"
                  >
                    Download QRCode
                  </button>
                  {errors.qr_code && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors.qr_code}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
          <div className="card card-flush py-4">
            <div className="card-header">
              <div className="card-title">
                <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="col-12 mb-3">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className="required form-label"> {intl.formatMessage({ id: 'Form.Name' })}</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control py-4"
                    value={data.name}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'Form.Name' })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className="required form-label"> {intl.formatMessage({ id: 'Form.Email' })} </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control mb-2"
                    value={data.email}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'Form.Email' })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className="required form-label"> {intl.formatMessage({ id: 'Form.Phone' })}</label>
                  <input
                    type="number"
                    name="phone"
                    className="form-control py-4"
                    value={data.phone}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'Form.Phone' })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.phone && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors.phone}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className=" col-12 mb-3">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className="form-label"> {intl.formatMessage({ id: 'Form.BusinessWebsite' })}</label>
                  <input type="text" name="website" className="form-control py-4" value={data.website} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.BusinessWebsite' })} />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.website &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.website}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className=" col-12 mb-3">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className="form-label"> {intl.formatMessage({ id: 'Form.review_link' })}</label>
                  <input type="text" name="review_link" className="form-control py-4" value={data.review_link} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.review_link' })} />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.review_link &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.review_link}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className=" form-label"> {intl.formatMessage({ id: 'Form.facebook' })} </label>
                  <input
                    type="text"
                    name="facebook"
                    className="form-control mb-2"
                    value={data.facebook}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'Form.facebook' })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.facebook && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors.facebook}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className=" form-label"> {intl.formatMessage({ id: 'Form.youtube' })} </label>
                  <input
                    type="text"
                    name="youtube"
                    className="form-control mb-2"
                    value={data.youtube}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'Form.youtube' })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.youtube && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors.youtube}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className=" form-label"> {intl.formatMessage({ id: 'Form.twitter' })} </label>
                  <input
                    type="text"
                    name="twitter"
                    className="form-control mb-2"
                    value={data.twitter}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'Form.twitter' })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.twitter && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors.twitter}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className=" form-label"> {intl.formatMessage({ id: 'Form.instagram' })} </label>
                  <input
                    type="text"
                    name="instagram"
                    className="form-control mb-2"
                    value={data.instagram}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'Form.instagram' })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.instagram && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors.instagram}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className=" form-label"> {intl.formatMessage({ id: 'Form.tiktok' })} </label>
                  <input
                    type="text"
                    name="tiktok"
                    className="form-control mb-2"
                    value={data.tiktok}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'Form.tiktok' })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.tiktok && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors.tiktok}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {!loading && <span className="indicator-label">{intl.formatMessage({ id: 'Form.Edit' })} </span>}
              {loading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
