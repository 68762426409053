import React from 'react'
import logo from '../../../_metronic/assets/Img/Logo/logo.gif'
export const Loading = () => {
  return (
    <div className=' row flex-center  align-items-center' style={{minHeight:'100vh'}}>
      <div className='col-5 text-center'>

          <img src={process.env.REACT_APP_IMAGE_PATH + (localStorage.getItem('web_img') ?? '')} alt=""  style={{widows:'100px'}}  /> <br />
          loading ...
          <div className='loading_div_body'>
              <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
      </div>
    </div> 
  )
}
