import { Navigate, Outlet } from "react-router-dom";
import useAuthContext from "../Auth/AuthContext";
import { Loading } from "../pages/LoadingPage/Loading";
import moment from "moment";

const CheckLayout = () => {
  const { user } = useAuthContext();
  const day_date = moment().format('YYYY-MM-DD HH:mm:ss'); // Current date and time

  if (user === 0) return <Navigate to="/login" />;
  if (!user) return <Loading />;
 
  if (user.type !== 'admin') {
    if (!user.bussines) return <Navigate to="/Bussnies-Stepup" />;
    
    const expiry_date = moment(user.bussines?.expiry_date).format('YYYY-MM-DD');
    // Redirect to root if expiry date is in the past (i.e., expired)
    if (user.bussines.expiry_date && moment(expiry_date).isAfter(day_date)) {
      return <Navigate to="/" />;
    }
    // If expiry date is in the future, allow access
    if (!user.bussines.expiry_date || moment(expiry_date).isBefore(day_date)) {
      return <Outlet />;
    }
    return <Outlet />;
  }

};

export default CheckLayout;
